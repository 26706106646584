
import React, { useState, useCallback, useEffect } from 'react';
import { Form, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { translateText } from "../TranslateText";

const SearchBox = ({ categories }) => {
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const { t } = useTranslation();
  const language = useSelector((state) => state.language.language);

  const [translatedSelectedCategory, setTranslatedSelectedCategory] = useState('');
 
  const handleChange = (e) => {
    setKeyword(e.target.value);
  };

  const handleCategorySelect = async (category) => {
    if (keyword.trim()) {
      setKeyword('');
    }
    setSelectedCategory(category);
    // const translatedCategory = await translateText(category, language);
    // setTranslatedSelectedCategory(translatedCategory);
    navigate(`all-products/category/${category}`);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (keyword.trim()) {
      navigate(`all-products/search/${keyword}`);
    } else if (translatedSelectedCategory) {
      navigate(`all-products/category/${translatedSelectedCategory}`);
    } else {
      navigate('/');
    }
  };

  useEffect(() => {
    if (selectedCategory) {
      translateText(selectedCategory, language).then((translatedCategory) => {
        setTranslatedSelectedCategory(translatedCategory);
      });
    }
  }, [selectedCategory, language]);
  return (
    <Form onSubmit={submitHandler} className="ms-auto" style={{ position: 'relative',display: 'flex', transform: 'scale(0.8)' ,margin:'0px',zIndex:10}}>
      <div style={{display:"inline-flex"}}>
      <Form.Control
      id="search-box-input"
        type="text"
        name="q"
        onChange={handleChange}
        value={keyword}
        placeholder={t('search-bar-text')}
        className="mr-sm-2 ml-sm-5 left-handside"
      ></Form.Control>
      <Button type="submit "  className="p-2 right-handside">
        {t('search-bar-button')}
      </Button>
      </div>
    
      <span className="mx-2" style={{alignSelf: 'center', color:'white' }}>{t('or')}</span>
    
      <DropdownButton
  id="category-dropdown"
  title={translatedSelectedCategory || t('filter-text')}
  onSelect={handleCategorySelect}
  className="mr-sm-2 custom-dropdown-button"
>
  {categories.map((category, index) => (
    <Dropdown.Item key={categories[index]} eventKey={categories[index]}>
      {category}
    </Dropdown.Item>
  ))}
</DropdownButton>
    </Form>
  );
};

export default SearchBox;

