import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';


export const DirectionProvider = ({ children }) => {
  const language = useSelector((state) => state.language.language);
 
  useEffect(() => {
    var main = document.querySelector('main');
    var html = document.querySelector('html');
    console.log(main)
    if (language === 'he') {
      main.setAttribute('dir', 'rtl');
      html.setAttribute('lang', 'he');
    } else {
      main.setAttribute('dir', 'ltr');
      html.setAttribute('lang', 'en');
    }
  }, [language]);

  return children;
};
