import React, { useState, useEffect, useCallback } from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { useDispatch, useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap";
import { logout } from "../actions/userActions";
import SearchBox from "../screens/SearchBox";
import { useLocation, useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { Badge } from "react-bootstrap";
import axios from "axios";
import LanguageSwitcher from './LanguageSwitcher'
import { useTranslation } from 'react-i18next';
import i18next from "i18next";
import { slide as Menu } from 'react-burger-menu'

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const language = useSelector(state => state.language.language);
  const location = useLocation();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;
  const totalQty = !cartItems
    ? 0
    : cartItems.reduce((accumulator, currentItem) => {
        return accumulator + currentItem.qty;
      }, 0);
  const logoutHandler = () => {
    dispatch(logout());
    navigate("/");
  };
  const goToCartHandler = () => {
    navigate("/cart");
  };

  const [categories, setCategories] = useState([]);
  const fetchCategories = useCallback(async () => {
    try {
     
      const { data } = await axios.get(`/api/products/categories/${language}`);
      setCategories(data);
    } catch (error) {
    }
  }, [language]);
  
  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);


  const isHomePage = location.pathname === "/";

  return (
    <header>
      <Navbar bg="dark" variant="dark" expand="lg" collapseOnSelect>
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand>Artifex</Navbar.Brand>
          </LinkContainer>

          <NavDropdown style={{ color: "white" }} title={t('menu')} id="menu">
            <LinkContainer to="/all-products">
              <NavDropdown.Item>{t('products')} </NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/about-us">
              <NavDropdown.Item>{t('about-us')} </NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/contact-us">
              <NavDropdown.Item>{t('contact-us')} </NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/faq">
              <NavDropdown.Item>{t('faq')} </NavDropdown.Item>
            </LinkContainer>
          </NavDropdown>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <SearchBox categories={categories} />

            <Nav className="ms-auto scale(0.8)">
              <LinkContainer
                to="/cart"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
              >
                <div>
                  <Link
                    style={{ color: "white" }}
                    to="/cart"
                    onClick={goToCartHandler}
                  >
                    <FontAwesomeIcon icon={faShoppingCart} />
                  </Link>
                  <Badge variant="warning" className="ml-1" pill>
                    {userInfo ? totalQty : ""}
                  </Badge>
                </div>
              </LinkContainer>
              {userInfo ? (
                <NavDropdown title={userInfo.name} id="username">
                  <LinkContainer to="/profile">
                    <NavDropdown.Item>Profile</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Item onClick={logoutHandler}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <LinkContainer to="/login">
                  <Nav.Link>
                    <i className="fas fa-user"></i> Sign In
                  </Nav.Link>
                </LinkContainer>
              )}
              {userInfo && userInfo.isAdmin && (
                <NavDropdown title={t('Admin')} id="adminmenu">
                  <LinkContainer to="/admin/userlist">
                    <NavDropdown.Item>{t('Users')}</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/admin/productlist">
                    <NavDropdown.Item>{t('products')}</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/admin/orderlist">
                    <NavDropdown.Item>{t('Orders')}</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/admin/articlelist">
                    <NavDropdown.Item>{t('Articles')}</NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              )}
            </Nav>
          </Navbar.Collapse>


<LanguageSwitcher/>
        </Container>

      </Navbar>
     { isHomePage && <div className="scrolling-text">
  <div className="text-container">
    <p className="text">{t('headerRunningText1')}</p>
    <p className="text">{t('headerRunningText2')}</p>
  </div>
</div>
}
    </header>
  );
};

export default Header;

