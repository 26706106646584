import React from 'react';

const VideoBackground = ({ videoSrc }) => {
  const videoBackgroundStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
    overflow: 'hidden',
  };

  const videoBackgroundAfterStyle = {
    content: '',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 255, 0.1)',
    backdropFilter: 'blur(0.5px)',
    zIndex: 1,
  };

  return (
    <div style={videoBackgroundStyle}>
      <div style={videoBackgroundAfterStyle}></div>
      <video
        src={videoSrc}
        autoPlay
        loop
        muted
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
      ></video>
    </div>
  );
};

export default VideoBackground;
