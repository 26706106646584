/* eslint-disable no-multi-str */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { listArticleDetails, updateArticle } from "../actions/articleActions";
import { ARTICLE_UPDATE_RESET } from "../constants/articleConstants";
import { Editor } from "@tinymce/tinymce-react";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import { Link } from "react-router-dom";

const ArticleEditScreen = () => {
  const { id } = useParams();
  const articleId = id;
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [content, setContent] = useState("");
  const [author, setAuthor] = useState("");
  const [image, setImage] = useState("");
  const [tags, setTags] = useState("");
  const [nameHe, setNameHe] = useState("");
  const [contentHe, setContentHe] = useState("");
  const [categoryHe, setCategoryHe] = useState("");

  const dispatch = useDispatch();

  const articleDetails = useSelector((state) => state.articleDetails);
  const { loading, error, article } = articleDetails;

  const articleUpdate = useSelector((state) => state.articleUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = articleUpdate;

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: ARTICLE_UPDATE_RESET });
      navigate("/admin/articlelist");
    } else {
      if (!article || !article.name || article._id !== articleId) {
        dispatch(listArticleDetails(articleId));
      } else {
        setName(article.name);
        setContent(article.content);
        setImage(article.image);
        setAuthor(article.author);
        setCategory(article.category);

        setNameHe(article.nameHe);
        setContentHe(article.contentHe);
        setCategoryHe(article.categoryHe);
      }
    }
  }, [dispatch, articleId, article, successUpdate, navigate]);

  const submitHandler = (e) => {
    e.preventDefault();
    console.log(contentHe);
    dispatch(
      updateArticle({
        _id: articleId,
        name,
        category,
        content,
        author,
        image,
        tags: tags.split(",").map((tag) => tag.trim()),
        nameHe,
        contentHe,
        categoryHe
      })
    );
  };

  return (
    <>
    
      <Link to="/admin/articlelist" className="btn btn-light my-3">
        Go Back
      </Link>
      <FormContainer>
        <h1>Edit Article</h1>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="name"
                placeholder="Enter name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="category">
              <Form.Label>Category</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="content">
              <Form.Label>Content</Form.Label>
              <Editor
            apiKey="f0qen3vi2edeenyeqvrzg5wvvc7o2ysgs8desk0ndipd9oup"
            initialValue={content}
            init={{
              height: 300,
              menubar: false,
              plugins :[
                'advlist',
                'autolink',
                'lists',
                'link',
                'image',
                'charmap',
                // 'print',
                'preview',
                'anchor',
                'searchreplace',
                'visualblocks',
                'code',
                'fullscreen',
                'insertdatetime',
                'media',
                'table',
                // 'paste',
                // 'image-tools',
                'wordcount'
              ],
              toolbar:
                "undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | image | code | help",
                image_advtab: true,
                // paste_data_images: true,
                // images_upload_handler: function (blobInfo, success, failure) {
                //   success("data:" + blobInfo.blob().type + ";base64," + blobInfo.base64());
                // },
            }}
            onEditorChange={(newContent) => setContent(newContent)}
          />
            </Form.Group>

            <Form.Group controlId="author">
              <Form.Label>Author</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter author name"
                value={author}
                onChange={(e) => setAuthor(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="image">
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter image url"
                value={image}
                onChange={(e) => setImage(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="tags">
              <Form.Label>Tags</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter tags separated by commas"
                value={tags}
                onChange={(e) => setTags(e.target.value)}
              ></Form.Control>
            </Form.Group>



            <Form.Group controlId="nameHe">
    <Form.Label>Name (Hebrew)</Form.Label>
    <Form.Control
        type="name"
        placeholder="Enter Hebrew name"
        value={nameHe}
        onChange={(e) => setNameHe(e.target.value)}
    ></Form.Control>
</Form.Group>

<Form.Group controlId="contentHe">
    <Form.Label>Content (Hebrew)</Form.Label>
    <Editor
        apiKey="f0qen3vi2edeenyeqvrzg5wvvc7o2ysgs8desk0ndipd9oup"
        initialValue={contentHe}
        init={{
          height: 300,
          menubar: false,
          plugins :[
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            // 'print',
            'preview',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            // 'paste',
            // 'image-tools',
            'wordcount'
          ],
          toolbar:
            "undo redo | formatselect | bold italic backcolor | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | image | code | help",
            image_advtab: true,
            // paste_data_images: true,
            // images_upload_handler: function (blobInfo, success, failure) {
            //   success("data:" + blobInfo.blob().type + ";base64," + blobInfo.base64());
            // },
        }}
        onEditorChange={(newContent) => setContentHe(newContent)}

      />
</Form.Group>

<Form.Group controlId="categoryHe">
    <Form.Label>Category (Hebrew)</Form.Label>
    <Form.Control
        type="text"
        placeholder="Enter Hebrew category"
        value={categoryHe}
        onChange={(e) => setCategoryHe(e.target.value)}
    ></Form.Control>
</Form.Group>



            <Button type="submit" className="round" variant="primary">
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default ArticleEditScreen;
