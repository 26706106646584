import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

export async function translateText(text, toLanguage) {
  const key = 'b93dfaa5a7b44ec68fef9f43db7106a9';
  const endpoint = 'https://api.cognitive.microsofttranslator.com'; // or use a regional endpoint
  const route = '/translate?api-version=3.0';
  const params = `&to=${toLanguage}`;
  const headers = {
    'Ocp-Apim-Subscription-Key': key,
    'Ocp-Apim-Subscription-Region': 'westeurope', // specify the region here
    'Content-type': 'application/json',
    'X-ClientTraceId': uuidv4().toString()
  };

  // Split the text into an array with <img> tags as separator
  const splitText = text.split(/(<img[^>]*>)/g);

  const requestBody = splitText.map(chunk => {
    // Check if the chunk is not an <img> tag
    if (!/<img[^>]*>/.test(chunk)) {
      return {
        'Text': chunk,
      };
    }
    return null;
  }).filter(item => item); // remove nulls

  const translatedText = await axios.post(endpoint + route + params, requestBody, { headers })
    .then(response => {
      if(response.data && response.data.length > 0) {
        // Merge translated chunks and un-translated <img> tags
        let index = 0;
        return splitText.map(chunk => {
          // Return translations for non-img chunks and the original chunk for <img>
          if (!/<img[^>]*>/.test(chunk)) {
            return response.data[index++].translations[0].text;
          }
          return chunk;
        }).join("");
      } else {
        return text;
      }
    })
    .catch(err => console.error(`Error in translation API: ${err}`));
    
  return translatedText;
}
