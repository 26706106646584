
import React,{useState,useEffect} from "react";
import { useSelector } from "react-redux";
import { Card, Button } from "react-bootstrap";
import { translateText } from "../TranslateText";
import { useTranslation } from 'react-i18next';

const Article = ({ article }) => {
  const { t } = useTranslation();
  const language = useSelector(state => state.language.language);
  const [translatedArticleAuthor, setTranslatedArticleAuthor] = useState("");

  useEffect(() => {
    if (article) {
      if (language === 'he') {
        // Translate product name and description
          translateText(article.author, 'he')
          .then(translatedName => setTranslatedArticleAuthor(translatedName))
          .catch(err => console.error(err));
  
      } else {
        // If the language is not 'he', use the original English text
      
        setTranslatedArticleAuthor(article.author);
      
      }
    }
  }, [article, language]);  // Make sure to add 'language' to the dependency array


  return (
    <Card
      className="my-3 p-3 rounded"
      style={{
        width: "20em",
        height: "32em",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Card.Img
        src={article.image}
        variant="top"
        className="mx-auto d-block"
        style={{
          width: "90%",
          height: "200px",
          objectFit: "cover",
          marginBottom: "1rem",
          marginTop: "1rem",
        }}
      />
      <Card.Body className="text-center" style={{ flex: 1 }}>
        <Card.Title
          as="div"
          className="mb-0"
          style={{
            height: "3rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <strong>{language === 'he' ? article.nameHe : article.name}</strong>
        </Card.Title>
      </Card.Body>
      <div
        className="text-center mb-2"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          flex: 1,
        }}
      >
        <Card.Text as="div">
        {t('article-author')}: {translatedArticleAuthor}
        </Card.Text>
        <Card.Text as="div">{t('article-category')}: {language === 'he' ? article.categoryHe : article.category}</Card.Text>
        <Card.Text as="div">
        {t('article-date')}: {new Date(article.date).toLocaleDateString("en-GB")}
        </Card.Text>
        <Card.Text>
        <Button
          className="round mt-1"
          style={{ transform: "scale(0.9)" ,maxWidth:"15em"}}
          variant="primary"
          href={`/article/${article._id}`}
        >
        {t('article-button')}
        </Button>
        </Card.Text>
        
      </div>
    </Card>
  );
};
export default Article;



