
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { Table, Button, Row, Col, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate } from 'react-router';
import Message from '../components/Message';
import Loader from '../components/Loader';
import {
  listArticles,
  deleteArticle,
  // createArticle,
} from '../actions/articleActions';
import { ARTICLE_CREATE_RESET } from '../constants/articleConstants';


const ArticleListScreen = () => {
  const dispatch = useDispatch();
  const articleList = useSelector((state) => state.articleList);
  const { loading, error, articles } = articleList;

  
  const [search, setSearch] = useState('');

  const filteredArticles = articles?.filter((article) =>
    article.name.toLowerCase().includes(search.toLowerCase())
  );

  useEffect(() => {
    dispatch({ type: ARTICLE_CREATE_RESET });
    dispatch(listArticles());
  }, [dispatch]);

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure you want to delete this article?')) {
      dispatch(deleteArticle(id));
    }
  };

  


  return (
    <>
      <Row className="align-items-center">
        <Col>
          <h1>Articles</h1>
        </Col>
        <Col style={{ display: 'flex', justifyContent: 'end' }}>
          <Link
            to="/admin/article/create"
            className="btn btn-primary my-3 round"
          >
            Create Article
          </Link>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Control
            type="text"
            placeholder="Search article name"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Col>
      </Row>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <Table striped bordered hover responsive className="table-sm">
          <thead>
            <tr>
              <th>ID</th>
              <th>NAME</th>
              <th>AUTHOR</th>
              <th>CATEGORY</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredArticles.map((article) => (
              <tr key={article._id}>
                <td>{article._id}</td>
                <td>{article.name}</td>
                <td>{article.author}</td>
                <td>{article.category}</td>
                <td>
                  <LinkContainer to={`/admin/article/${article._id}/edit`}>
                    <Button variant="light" className="btn-sm round">
                      <i className="fas fa-edit"></i>
                    </Button>
                  </LinkContainer>
                  <Button
                    variant="danger"
                    className="btn-sm round"
                    onClick={() => deleteHandler(article._id)}
                  >
                    <i className="fas fa-trash"></i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default ArticleListScreen;

