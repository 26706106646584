import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import { register, generateOtp, verifyOtp } from "../actions/userActions";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from 'react-i18next';
import Meta from "../components/Meta";
const RegisterScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState(null);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [isRecaptchaChecked, setIsRecaptchaChecked] = useState(false);
  const [otp, setOtp] = useState(""); // New state for OTP
  const [otpGenerated, setOtpGenerated] = useState(false); // New state for checking if OTP is generated
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false); // New state for Privacy Policy checkbox
  const [privacyPolicyError, setPrivacyPolicyError] = useState(false); // New state for Privacy Policy error
  const [marketingMaterialAccepted, setMarketingMaterialAccepted] =
    useState(false); // New state for Marketing Material checkbox
    const { t } = useTranslation();
  
  const dispatch = useDispatch();

  const userRegister = useSelector((state) => state.userRegister);
  const { loading, error, userInfo } = userRegister;

  const redirect = location.search ? location.search.split("=")[1] : "/";
  useEffect(() => {
    if (userInfo && userInfo._id) {
      navigate(redirect);
    }
  }, [userInfo, redirect, navigate]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Passwords do not match");
    } else if (!isRecaptchaChecked) {
      setMessage("Please verify the reCAPTCHA");
    } else if (!privacyPolicyAccepted) {
      // Check if Privacy Policy is accepted
      setPrivacyPolicyError(true);
    } else if (!otpGenerated) {
      dispatch(generateOtp(email));
      setOtpGenerated(true);
    } else {
      setPrivacyPolicyError(false);
      dispatch(verifyOtp(email, otp))
        .then((response) => {
          
          if (response.error) {
            throw new Error("OTP verification failed");
          }
          return dispatch(register(name, email, password, recaptchaValue));
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleRecaptchaChange = (value) => {
    setIsRecaptchaChecked(true);
    setRecaptchaValue(value);
  };

  return (
    <Meta title={t('sign-up-text')}>
    <FormContainer>
      <h1>{t('sign-up-text')}</h1>
      {message && <Message variant="danger">{message}</Message>}
      {error && <Message variant="danger">{error}</Message>}
      {loading && <Loader />}
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="name">
          <Form.Label>{t('name-text')}</Form.Label>
          <Form.Control
            type="name"
            placeholder={t('enter-name-text')}
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="email">
          <Form.Label>{t('email-address-text')}</Form.Label>
          <Form.Control
            type="email"
            placeholder={t('enter-email-text')}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>

        {otpGenerated && (
          <Form.Group controlId="otp">
            <Form.Label>{t('otp-text')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t('enter-otp-text')}
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            ></Form.Control>
          </Form.Group>
        )}

        <Form.Group controlId="password">
          <Form.Label>{t('password-text')}</Form.Label>
          <Form.Control
            type="password"
            placeholder={t('enter-password-text')}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="confirmPassword">
          <Form.Label>{t('confirm-password-text')}</Form.Label>
          <Form.Control
            type="password"
            placeholder={t('confirm-password-text')}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="privacyPolicy">
          <Form.Check
            type="checkbox"
            label={
              <span>
               {t('privacy-policy-statement-text')}{" "}
                <Link to="/privacy-policy">{t('privacy-policy')}</Link>.
              </span>
            }
            checked={privacyPolicyAccepted}
            onChange={(e) => setPrivacyPolicyAccepted(e.target.checked)}
          />
        </Form.Group>

        <Form.Group controlId="marketingMaterial">
          <Form.Check
            type="checkbox"
            label={t('marketing-material-label')}
            checked={marketingMaterialAccepted}
            onChange={(e) => setMarketingMaterialAccepted(e.target.checked)}
          />
        </Form.Group>

        {privacyPolicyError && (
          <Message variant="danger">
           {t('have-to-accept-privacy-policy')}
          </Message>
        )}

        <ReCAPTCHA
            //  sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
          sitekey={process.env.REACT_APP_SITE_KEY}
          onChange={handleRecaptchaChange}
          style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}
        />

        <Button  className="round" type="submit" variant="primary" disabled={!isRecaptchaChecked}>
        {t('register-text')}
        </Button>
      </Form>

      <Row className="py-3">
        <Col>
        {t('have-an-account-text')}{" "}
          <Link to={redirect ? `/login?redirect=${redirect}` : "/login"}>
          {t('login-button-text')}
          </Link>
        </Col>
      </Row>
    </FormContainer>
    </Meta>
  );
};

export default RegisterScreen;
