import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form } from 'react-bootstrap';
import { useParams, Link } from 'react-router-dom'; 
import Message from '../components/Message';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';
import { resetPassword } from '../actions/userActions';
import { useTranslation } from 'react-i18next';
import Meta from "../components/Meta";
const NewPasswordScreen = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const { t } = useTranslation();
  
  const dispatch = useDispatch();
  const passwordReset = useSelector(state => state.passwordReset);
  
  let { token } = useParams();

  const submitHandler = (e) => {
   
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage('Passwords do not match');
    } else {
      dispatch(resetPassword(token, password));
    }
  };
  

  useEffect(() => {
    if (passwordReset.error) {

      setMessage(t('password-error-message'));
    }
  }, [passwordReset.error]);
  
  useEffect(() => {
    if (passwordReset.success) {
      setSuccessMessage(t('password-error-success'));
    }
  }, [passwordReset.success]);
  

  return (
    <Meta title={t('new-password-screen-title')}>
    <FormContainer>
      <h1>{t('new-password-screen-title')}</h1>
      {message && <Message variant='danger'>{message}</Message>}
      {successMessage && <Message variant='success'>{successMessage}</Message>}
      {passwordReset.loading && <Loader />}
      {!successMessage && (
        <Form onSubmit={submitHandler}>
          <Form.Group controlId='password'>
            <Form.Label>{t('new-password-text')}</Form.Label>
            <Form.Control
              type='password'
              placeholder={t('enter-new-password-text')}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='confirmPassword'>
            <Form.Label>{t('confirm-new-password-text')}</Form.Label>
            <Form.Control
              type='password'
              placeholder={t('confirm-new-password-text')}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Button type='submit' className='round' style={{marginTop:'1.5rem'}} variant='primary'>
          {t('update-password-button-text')}
          </Button>
        </Form>
      )}
      {successMessage && <Link to='/login' className='btn btn-primary round'>{t('login-to-your-account')}</Link>}
    </FormContainer>
    </Meta>
  );
};

export default NewPasswordScreen;
