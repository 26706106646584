import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div>
      <h1>Privacy Policy</h1>
      <p>Artifex, the operator of the website www.r-tifex.com, is committed to protecting the privacy of our customers. This privacy policy describes how we collect, use, and protect the personal information you provide to us through our website.</p>

      <h2>1. Collection of Personal Information</h2>
      <p>We may collect the following types of personal information from you:</p>
      <ul>
        <li>Full name</li>
        <li>Email address</li>
        <li>Shipping address</li>
        <li>Payment information</li>
      </ul>
      <p>We collect this personal information through online forms and social media logins.</p>

      <h2>2. Use of Personal Information</h2>
      <p>We use your personal information for the following purposes:</p>
      <ul>
        <li>To process your orders and shipments</li>
        <li>To send marketing emails, if you have opted in to receive them</li>
        <li>To allow you to log in to our website</li>
      </ul>

      <h2>3. Sharing of Personal Information</h2>
      <p>We may share your personal information with third-party service providers, such as payment processors and analytics tools, in order to provide you with our services.</p>

      <h2>4. Protection of Personal Information</h2>
      <p>We use encryption, firewalls, and regular security updates to protect your personal information from unauthorized access, disclosure, or destruction.</p>

      <h2>5. Retention of Personal Information</h2>
      <p>We keep your personal information until you ask us to delete it.</p>

      <h2>6. Your Rights</h2>
      <p>You have the right to access, correct, or delete your personal information at any time by contacting us using the contact information provided below.</p>

      <h2>7. Cookies and Tracking Technologies</h2>
      <p>We use cookies and other tracking technologies on our website to improve your user experience. Once you enter our website, you will be notified that we are using cookies and can choose to allow or not.</p>

      <h2>8. Data Protection Laws</h2>
      <p>We do not specifically comply with any data protection laws or regulations at this time.</p>

      <h2>9. Contact Information</h2>
      <p>If you have any questions or concerns about our privacy policy, or if you would like to access, correct, or delete your personal information, please contact us at:</p>
      <p>Artifex</p>
      <p>Email: info@r-tifex.com</p>
    </div>
  );
};

export default PrivacyPolicy;
