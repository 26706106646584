


import React from 'react';
import { Pagination } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useNavigate } from 'react-router-dom';

const Paginate = ({ pages, page, isAdmin = false, keyword = '' }) => {
  const navigate = useNavigate();

  const handlePageClick = (pageNumber) => {
    if (isAdmin) {
      navigate(`/admin/productlist/${pageNumber}`);
    } else if (keyword) {
      navigate(`/all-products/search/${keyword}/page/${pageNumber}`);
    } else {
      navigate(`/all-products/page/${pageNumber}`);
    }
  };

  return (
    pages > 1 && (
      <Pagination  style={{ justifyContent: 'center' }}>
        {[...Array(pages).keys()].map((pageNumber) => (
          <LinkContainer
         
            key={pageNumber + 1}
            to={
              !isAdmin
                ? keyword
                  ? `/all-products/search/${keyword}/page/${pageNumber + 1}`
                  : `/all-products/page/${pageNumber + 1}`
                : `/admin/productlist/${pageNumber + 1}`
            }
          >
            <Pagination.Item
              active={pageNumber + 1 === page}
              onClick={() => handlePageClick(pageNumber + 1)}
             
            >
              {pageNumber + 1}
            </Pagination.Item>
          </LinkContainer>
        ))}
      </Pagination>
    )
  );
};

export default Paginate;
