import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Article from './Article';  // Import Article component
import { useTranslation } from 'react-i18next';
const SampleNextArrow = (props) => {


  const { onClick } = props;
  return (
    <button className="slick-arrow slick-next" onClick={onClick}>
      Next
    </button>
  );
};

const SamplePrevArrow = (props) => {
  const { onClick } = props;
  return (
    <button className="slick-arrow slick-prev" onClick={onClick}>
      Prev
    </button>
  );
};

const ArticleSwiper = ({ articles }) => {
  const { t } = useTranslation();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: Math.min(4, articles.length),
    slidesToScroll: 1,
    nextArrow: articles.length > 1 ? <SampleNextArrow /> : null,
    prevArrow: articles.length > 1 ? <SamplePrevArrow /> : null,
    cssEase: "linear",
    variableWidth: true,
    centerPadding: "0px",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: Math.min(2, articles.length),
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div
      className="article-swiper"
      style={{
        marginTop: "3rem",
        marginBottom: "2rem",
        transform: "scale(0.9)",
      }}
    >
      <div className="generic-css">
        <h3 className="white-colored">{t('article-section')}</h3>
      </div>
      {articles && articles.length > 0 && (
        <Slider {...settings}>
          {articles.map((article, index) => (
            <div key={index} className="article-swiper-slide">
              <Article article={article}/>  {/* Use Article component here */}
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
};

export default ArticleSwiper;

