import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { PayPalButton } from "react-paypal-button-v2";
import { Link } from "react-router-dom";
import { Row, Col, ListGroup, Image, Card, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import {
  getOrderDetails,
  payOrder,
  deliverOrder,
} from "../actions/orderActions";
import {
  ORDER_PAY_RESET,
  ORDER_DELIVER_RESET,
} from "../constants/orderConstants";
import { useTranslation } from "react-i18next";
import { getRate } from "../currencyExchange";

const OrderScreen = () => {
  const id = useParams();
  const orderId = id;
  const navigate = useNavigate();

  const [sdkReady, setSdkReady] = useState(false);

  const dispatch = useDispatch();

  const orderDetails = useSelector((state) => state.orderDetails);

  const { order, loading, error } = orderDetails;

  const orderPay = useSelector((state) => state.orderPay);
  const { loading: loadingPay, success: successPay } = orderPay;

  const orderDeliver = useSelector((state) => state.orderDeliver);
  const { loading: loadingDeliver, success: successDeliver } = orderDeliver;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const { t } = useTranslation();
  const language = useSelector((state) => state.language.language);
  const [currencySymbol, setCurrencySymbol] = useState("$");
  const [exchangeRate, setExchangeRate] = useState(1);

  if (!loading) {
    //   Calculate prices
    const addDecimals = (num) => {
      return (Math.round(num * 100) / 100).toFixed(2);
    };

    order.itemsPrice = addDecimals(
      order.orderItems.reduce((acc, item) => acc + item.price * item.qty, 0)
    );
  }

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }

    const addPayPalScript = async () => {
      const { data: clientId } = await axios.get("/api/config/paypal");

      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}`;
      script.async = true;
      script.onload = () => {
        setSdkReady(true);
      };
      document.body.appendChild(script);
    };

    if (!order || successPay || successDeliver || order._id !== orderId.id) {
      dispatch({ type: ORDER_PAY_RESET });
      dispatch({ type: ORDER_DELIVER_RESET });
      dispatch(getOrderDetails(orderId));
    } else if (!order.isPaid) {
      if (!window.paypal) {
        addPayPalScript();
      } else {
        setSdkReady(true);
      }
    }
  }, [
    dispatch,
    navigate,
    userInfo,
    orderId,
    successPay,
    successDeliver,
    order,
    language,
  ]);

  useEffect(() => {
    const fetchExchangeRate = async () => {
      let rate = await getRate();
      setExchangeRate(rate);
    };
    setCurrencySymbol(language === "he" ? "₪" : "$");
  }, [language]);

  const displayPrice = (price) => {
    const convertedPrice = (
      price * (language === "he" ? 1 : exchangeRate)
    ).toFixed(2);
    return `${currencySymbol}${convertedPrice}`;
  };

  const sendOrderDetailsEmail = async (order) => {
    const data = {
      orderId: order._id,
      name: order.user.name,
      email: order.user.email,
      shippingAddress: order.shippingAddress,
      orderItems: order.orderItems,
      itemsPrice: order.itemsPrice,
      shippingPrice: order.shippingPrice,
      taxPrice: order.taxPrice,
      totalPrice: order.totalPrice,
    };

    const response = await fetch("https://formspree.io/f/meqwpwbb", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      console.log("Error sending order details email");
    }
  };
  const successPaymentHandler = (paymentResult) => {
    dispatch(payOrder(orderId, paymentResult));

    sendOrderDetailsEmail(order);
  };

  const deliverHandler = () => {
    dispatch(deliverOrder(order));
  };

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant="danger">{error}</Message>
  ) : (
    <>
      <h1>
        {" "}
        {t("order-screen-order-text")} {order._id}
      </h1>
      <Row>
        <Col md={8}>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <h2> {t("place-order-screen-shipping-text")}</h2>
              <p>
                <strong> {t("name-text")}: </strong> {order.user.name}
              </p>
              <p>
                <strong> {t("contact-us-email")}: </strong>{" "}
                <a href={`mailto:${order.user.email}`}>{order.user.email}</a>
              </p>
              <p>
                <strong> {t("place-order-screen-address-text")}:</strong>
                {order.shippingAddress.address}, {order.shippingAddress.city}{" "}
                {order.shippingAddress.postalCode},{" "}
                {order.shippingAddress.country}
              </p>
              {order.isDelivered ? (
                <Message variant="success">
                  {t("order-screen-delivered-on")} {order.deliveredAt}
                </Message>
              ) : (
                <Message variant="danger">
                  {" "}
                  {t("order-screen-not-delivered")}
                </Message>
              )}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2> {t("place-order-screen-shipping-text")}</h2>
              <p>
                <strong> {t("place-order-screen-method-text")} </strong>
                {order.paymentMethod}
              </p>
              {order.isPaid ? (
                <Message variant="success">
                  {" "}
                  {t("order-screen-paid-on")}
                  {order.paidAt}
                </Message>
              ) : (
                <Message variant="danger">
                  {" "}
                  {t("order-screen-not-paid")}
                </Message>
              )}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2> {t("place-order-screen-order-items-text")}</h2>
              {order.orderItems.length === 0 ? (
                <Message> {t("order-screen-order-empty")}</Message>
              ) : (
                <ListGroup variant="flush">
                  {order.orderItems.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col md={1}>
                          <Image
                            src={item.image}
                            alt={language === "he" ? item.nameHe : item.name}
                            fluid
                            rounded
                          />
                        </Col>
                        <Col>
                          <Link to={`/product/${item.product}`}>
                            {language === "he" ? item.nameHe : item.name}
                          </Link>
                        </Col>
                        <Col md={4}>
                          {language === "he" ? (
                            <>
                              {displayPrice(item.qty * item.price)} ={" "}
                              {displayPrice(item.price)}{"x"}{item.qty}
                            </>
                          ) : (
                            <>
                              {item.qty}{' x '}{displayPrice(item.price)} ={" "}
                              {displayPrice(item.qty * item.price)}
                            </>
                          )}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={4}>
          <Card>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <h2> {t("place-order-screen-order-summery-text")}</h2>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col> {t("place-order-screen-items-text")}</Col>
                  <Col>
                    {currencySymbol}
                    {order.itemsPrice}
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col> {t("place-order-screen-shipping-text")}</Col>
                  <Col>
                    {currencySymbol}
                    {order.shippingPrice}
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col> {t("place-order-screen-tax-text")}</Col>
                  <Col>
                    {currencySymbol}
                    {order.taxPrice}
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col> {t("place-order-screen-subtotal-text")}</Col>
                  <Col>
                    {currencySymbol}
                    {order.totalPrice}
                  </Col>
                </Row>
              </ListGroup.Item>
              {!order.isPaid && (
                <ListGroup.Item>
                  {loadingPay && <Loader />}
                  {!sdkReady ? (
                    <Loader />
                  ) : (
                    <PayPalButton
                      amount={order.totalPrice}
                      onSuccess={successPaymentHandler}
                    />
                  )}
                </ListGroup.Item>
              )}
              {loadingDeliver && <Loader />}
              {userInfo &&
                userInfo.isAdmin &&
                order.isPaid &&
                !order.isDelivered && (
                  <ListGroup.Item className="generic-css">
                    <Button
                      type="button"
                      className="btn btn-block round "
                      onClick={deliverHandler}
                    >
                      {t("order-screen-mark-as-delivered-text")}
                    </Button>
                  </ListGroup.Item>
                )}
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default OrderScreen;
