import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

export const Footer =  () => {
  const [bgColor, setBgColor] = useState("white");
  const location = useLocation();
  const { t } = useTranslation();



    useEffect(() => {
      // Check if the current route is "/" or starts with "/all/products"
      const isWhiteFontRoute =
        location.pathname === "/" ||
        location.pathname.startsWith("/all-products");
  
      if (isWhiteFontRoute) {
        setBgColor("white");
      } else {
        setBgColor("black");
      }
    }, [location]);
 



  return (
    <footer className="footer" style={{ color: bgColor }} >
      <Container>
        <Row>
          <Col className="text-center" style={{ 
            fontSize: "16px",
            fontWeight: "bold",
            color:bgColor
          }}>
            {t('copyright-text')} &copy; {t('artifex-text')}
          </Col>
        </Row>
        <Row>
          <Col className="text-center" style={{ 
            display: "flex", 
            justifyContent: "center", 
            alignItems: "center",
            marginTop: "1rem",
            marginBottom: "1rem"
          }} >
             <div className="social-media-bar" style={{ 
              display: "flex", 
              justifyContent: "center", 
              alignItems: "center"
            }}>
              <a href="https://www.facebook.com" style={{ 
                color: bgColor, 
                margin: "0 5x"
              }}  target ="_blank"
                 rel="noreferrer">
                <i className="fab fa-facebook"></i>
              </a>
              <a href="https://www.twitter.com" style={{ 
                color: bgColor, 
                margin: "0 5px"
              }}  target ="_blank"
              rel="noreferrer">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="https://www.instagram.com" style={{ 
                color: bgColor, 
                margin: "0 5px"
              }}  target ="_blank"
              rel="noreferrer"> 
                <i className="fab fa-instagram"></i>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

