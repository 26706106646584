import React, { useState, useEffect } from "react";
import { Link, Navigate, Route, Routes } from "react-router-dom";
import { useNavigate, useLocation } from "react-router";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import { login } from "../actions/userActions";
import ShippingScreen from "./ShippingScreen";
import { loadUserCartItems } from "../actions/cartActions";
import ReCAPTCHA from "react-google-recaptcha";
import { generateOtp, verifyOtp,checkOtpEnabled  } from '../actions/userActions'; // Import these actions
import { useTranslation } from 'react-i18next';
import Meta from "../components/Meta";
const LoginScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [isRecaptchaChecked, setIsRecaptchaChecked] = useState(false);
  const [otp, setOtp] = useState(""); // New state for OTP
  const [otpGenerated, setOtpGenerated] = useState(false); // New state for checking if OTP is generated
  const { t } = useTranslation();
  
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;
  const redirect = location.search ? location.search.split("=")[1] : "/";

  const userOtpEnabled = useSelector((state) => state.userOtpEnabled);
const {enableOtp } = userOtpEnabled;

useEffect(() => {
  if (email) {
    dispatch(checkOtpEnabled(email));
  }
}, [dispatch, email]);

  useEffect(() => {
    if (userInfo && userInfo._id) {
      navigate(redirect);
      dispatch(loadUserCartItems());
    }
  }, [dispatch, navigate, userInfo, redirect]);

  const submitHandler = (e) => {
    e.preventDefault();
    
    if (enableOtp) { // Check if OTP is enabled
      if (!otpGenerated) {
        // If OTP is not generated, generate one
        dispatch(generateOtp(email));
        setOtpGenerated(true);
      } else {
        // If OTP is generated, verify it and then log in the user
        dispatch(verifyOtp(email, otp))
          .then(response => {
            if (response.error) {
              throw new Error('OTP verification failed');
            }
            return dispatch(login(email, password, recaptchaValue));
          })
          .catch(error => {
            console.error(error); // handle the error appropriately here
          });
      }
    } else {
      // If OTP is not enabled, directly log in the user
      dispatch(login(email, password, recaptchaValue));
    }
  };
  

  const handleRecaptchaChange = (value) => {
    setIsRecaptchaChecked(true);
    setRecaptchaValue(value);
  };

  return (
    <Meta title={t('sign-in-text')}>
    <FormContainer>
      <h1>{t('sign-in-text')}</h1>
      {error && <Message variant="danger">{error}</Message>}
      {loading && <Loader />}
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="email">
          <Form.Label>{t('email-address-text')}</Form.Label>
          <Form.Control
            type="email"
            placeholder={t('enter-email-text')}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>

        {otpGenerated && (
          <Form.Group controlId="otp">
            <Form.Label>{t('otp-text')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t('enter-otp-text')}
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            ></Form.Control>
          </Form.Group>
        )}

        {!otpGenerated && (
          <Form.Group controlId="password">
            <Form.Label>{t('password-text')}</Form.Label>
            <Form.Control
              type="password"
              placeholder={t('enter-password-text')}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            ></Form.Control>
          </Form.Group>
        )}

        <ReCAPTCHA
          // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
          sitekey={process.env.REACT_APP_SITE_KEY}
          onChange={handleRecaptchaChange}
          style={{marginTop:'1.5rem'}}
        />
        <Button
          type="submit"
          className="round"
          variant="primary"
          style={{ marginTop: "13px" }}
          disabled={!isRecaptchaChecked}
        >
          {otpGenerated ? t('verify-otp') : t('login-button-text')}
        </Button>
      </Form>

      <Row className="py-3">
        <Col>
        {t('new-customer-text')}{" "}
          <Link to={redirect ? `/register?redirect=${redirect}` : "/register"}>
          {t('register-text')}
          </Link>
        </Col>
        <Col className="text-right">
    <Link to="/reset-password">
    {t('forgot-password-text')}
      </Link>

  </Col>
      </Row>
    </FormContainer>
    </Meta>
  );
};

const LoginRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<LoginScreen />} />
      <Route path="/shipping" element={<ShippingScreen />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};


export { LoginScreen, LoginRoutes };
