import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Meta from '../components/Meta';

const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <Meta title={t('about-us-title')}>
    <Container>
      <Row className="justify-content-md-center">
        <Col xs={12} md={8}>
          <h1>{t('about-us-title')}</h1>
          <p>
          {t('about-us-p1')}
          </p>
          <p>
          {t('about-us-p2')}
          </p>
          <p>
          {t('about-us-p3')}
          </p>
          <p>
          {t('about-us-p4')}
          </p>
        </Col>
      </Row>
    </Container>
    </Meta>
  );
};

export default AboutUs;
