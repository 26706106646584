
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import Rating from './Rating'
import { translateText } from "../TranslateText"
import { getRate } from '../currencyExchange';  // Assuming you have this function in this file

const Product = ({ product }) => {

  const language = useSelector(state => state.language.language);
  const [exchangeRate, setExchangeRate] = useState(null);
  const [currencySymbol, setCurrencySymbol] = useState("$");

  useEffect(() => {
    const fetchExchangeRate = async () => {
      let rate = await getRate();
      setExchangeRate(rate);
    };
    setCurrencySymbol(language === "he" ? "₪" : "$");
    fetchExchangeRate();
  }, [language]);  

  const displayPrice = (price) => {
    return (language === 'he' ? price : (price * exchangeRate)).toFixed(2);
  }

  return (
    <Card className="my-3 p-3 rounded minHeight">
      <Link to={`/product/${product._id}`}>
        <Card.Img src={product.image} variant="top" />
      </Link>
  
      <Card.Body>
        <Link to={`/product/${product._id}`}>
          <Card.Title as="div">
            <strong>{language === 'he' ? product.nameHe : product.name}</strong>
          </Card.Title>
        </Link>
  
        <Card.Text as="div">
          <Rating value={product.rating} text={`${product.numReviews} reviews`} />
        </Card.Text>
  
        <Card.Text as="h3">{currencySymbol}{displayPrice(product.price)}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default Product;

