import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslations from './locales/en/translation.json';
import heTranslations from './locales/he/translation.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslations,
      },
      he: {
        translation: heTranslations,
      },
    },
    lng: 'he',
    fallbackLng: 'he',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;