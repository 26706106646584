
import React, { useEffect,useState  } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Row, Col } from "react-bootstrap";
import Product from "../components/Product.js";
import Message from "../components/Message.js";
import Loader from "../components/Loader.js";
import { listProducts } from "../actions/productActions.js";
import Paginate from "../components/Paginate.js";
import VideoBackground from '../components/VideoBackground.js'
import { useTranslation } from 'react-i18next';
import { translateText } from "../TranslateText";
import Meta from "../components/Meta";


const HomeScreen = () => {
  const params = useParams();
  const keyword = params.keyword;
  const pageNumber = params.pageNumber || 1;
  const categoryName = params.categoryName;
  const dispatch = useDispatch();
  const videoSrc = `${process.env.PUBLIC_URL}/videos/video.mp4`;
  const productList = useSelector(state => state.productList);
  const { loading, error, products,page,pages } = productList;
  const { t } = useTranslation();
  const language = useSelector((state) => state.language.language);

  //useEffect runs when the component loads
  useEffect(() => {
    if(keyword){}
    dispatch(listProducts(keyword,pageNumber,categoryName,language));
  }, [dispatch,keyword,pageNumber,categoryName,language]);

  return (
    <>
     <Meta title={categoryName || t('latest-products-text')}>
    <VideoBackground videoSrc={videoSrc} />

  
{/* <div style={{ display: 'flex', justifyContent: 'center' }}>
  <h1 id="homescreen-title" style={{color:'white'}}>
    {t('latest-products-text')}
  </h1>
</div> */}
<div style={{ display: 'flex', justifyContent: 'center' }}>
  <h1 id="homescreen-title" style={{ color: 'white' }}>
    {loading ? (
      categoryName || t('latest-products-text')
    ) : products.length === 0 ? (
      t('no-products-text')
    ) : (
      categoryName || t('latest-products-text')
    )}
  </h1>
</div>

     
      {loading ? (
        <Loader/>
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : products.length === 0 ? (
        <div>
         
        </div>
      ) : (
        <>
        <Row className="d-flex justify-content-center">
          {products.map((product) => (
            <Col key={product._id} sm={12} md={6} lg={4} xl={3}>
              <Product product={product} />
            </Col>
          ))}
        </Row>
        <Paginate
          pages={pages}
          page={page}
          keyword={keyword ? keyword : ''}
        />
      </>
    )}
    </Meta>
  </>
)
}

export default HomeScreen;
// import React, { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { useParams } from "react-router";
// import { Row, Col } from "react-bootstrap";
// import Product from "../components/Product.js";
// import Message from "../components/Message.js";
// import Loader from "../components/Loader.js";
// import { listProducts } from "../actions/productActions.js";
// import Paginate from "../components/Paginate.js";
// import VideoBackground from '../components/VideoBackground.js'
// import { useTranslation } from 'react-i18next';

// const HomeScreen = () => {
//   const params = useParams();
//   const keyword = params.keyword;
//   const pageNumber = params.pageNumber || 1;
//   const categoryName = params.categoryName;
//   const dispatch = useDispatch();
//   const videoSrc = `${process.env.PUBLIC_URL}/videos/video.mp4`;
//   const productList = useSelector(state => state.productList);
//   const { loading, error, products, page, pages } = productList;
//   const { t } = useTranslation();
//   const language = useSelector((state) => state.language.language);

//   useEffect(() => {
//     if(keyword){}
//     dispatch(listProducts(keyword,pageNumber,categoryName,language));
//   }, [dispatch,keyword,pageNumber,categoryName,language]);

//   return (
//     <>
//       <VideoBackground videoSrc={videoSrc} />
      
//       <div style={{ display: 'flex', justifyContent: 'center' }}>
//         <h1 id="homescreen-title" style={{ color: 'white' }}>
//           {loading ? (
//             categoryName || t('latest-products-text')
//           ) : (
//             categoryName || t('latest-products-text')
//           )}
//         </h1>
//       </div>

//       {loading ? (
//         <Loader/>
//       ) : error ? (
//         <Message variant='danger'>{error}</Message>
//       ) : (
//         <>
//           <Row className="d-flex justify-content-center">
//             {products.map((product) => (
//               <Col key={product._id} sm={12} md={6} lg={4} xl={3}>
//                 <Product product={product} />
//               </Col>
//             ))}
//           </Row>
//           <Paginate
//             pages={pages}
//             page={page}
//             keyword={keyword ? keyword : ''}
//           />
//         </>
//       )}
//     </>
//   )
// }

// export default HomeScreen;
