import axios from "axios";
import {
  CART_UPDATE_USER_CART,
  CART_REMOVE_ITEM,
  CART_SAVE_SHIPPING_ADDRESS,
  CART_SAVE_PAYMENT_METHOD,
  CART_CLEAR_ITEMS,
} from "../constants/cartConstants.js";

export const addToCart = (id, qty) => async (dispatch, getState) => {
  
  const { data } = await axios.get(`/api/products/${id}`);

  const { userLogin } = getState();
  const { userInfo } = userLogin;

  if (userInfo) {
    try {
      // Get user's cart items from the backend using the user's ID
      const userRes = await axios.get(`/api/users/cart/items`, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      });

      
      const userCart = userRes.data;

    
      // Check if the product exists in the user's cart items
      const existingCartItem = userCart.find(
        (item) => item.product.toString() === id
      );

      if (existingCartItem) {
        // Update the existing cart item's quantity
        existingCartItem.qty = qty;
      } else {
        // Add the new cart item
        userCart.push({
          product: data.product._id,
          name: data.product.name,
          nameHe:data.product.nameHe,
          image: data.product.image,
          price: data.product.price,
          countInStock: data.product.countInStock,
          qty,
        });
      }

      // Update the user's cart items in the backend
      await axios.put(
        `/api/users/cart/add`,
        {
          cartItems: userCart,
        },
        { headers: { Authorization: `Bearer ${userInfo.token}` } }
      );
      await dispatch({
        type: CART_UPDATE_USER_CART,
        payload: userCart,
      });
    } catch (error) {
      console.error("API Error:", error);
    }
  }
};

export const emptyCart = () => async (dispatch, getState) => {
  dispatch({
    type: CART_CLEAR_ITEMS,
    payload: [],
  });

  localStorage.removeItem("cartItems");

  const { userLogin } = getState();
  const { userInfo } = userLogin;

  if (userInfo) {
    
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.put(`/api/users/cart/empty`, {}, config);
  }
};


export const removeFromCart = (id) => async (dispatch, getState) => {
  // Keep this for non-user-specific cart functionality
  dispatch({
    type: CART_REMOVE_ITEM,
    payload: id,
  });

  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));

  const { userLogin } = getState();
  const { userInfo } = userLogin;
  

  if (userInfo) {
    try {
      const response = await axios.delete(`/api/users/cart/remove/${id}`, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      });
      console.log("API Response:", response);

      // Get the updated user's cart items from the backend
      const userRes = await axios.get(`/api/users/cart/items`, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      });

      const userCart = userRes.data;

      // Dispatch the action to update the cart items in the state
      dispatch({
        type: CART_UPDATE_USER_CART,
        payload: userCart,
      });

    } catch (error) {
      console.error("API Error:", error);
    }
  }
};





export const saveShippingAddress = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_SHIPPING_ADDRESS,
    payload: data,
  });

  localStorage.setItem("shippingAddress", JSON.stringify(data));
};

export const savePaymentMethod = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_PAYMENT_METHOD,
    payload: data,
  });

  localStorage.setItem("paymentMethod", JSON.stringify(data));
};


// export const loadUserCartItems = () => async (dispatch, getState) => {
//   const {
//     userLogin: { userInfo },
//   } = getState();

//   if (userInfo) {
//     try {
//       const response = await axios.get(`/api/users/cart/items`, {
//         headers: { Authorization: `Bearer ${userInfo.token}` },
//       });

//       response.data.forEach((item) =>
//         dispatch(addToCart(item.product, item.qty))
//       );
//     } catch (error) {
//       console.error("API Error:", error);
//     }
//   }
// };
export const loadUserCartItems = () => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  if (userInfo) {
    try {
      const response = await axios.get(`/api/users/cart/items`, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      });

      // Dispatch the action to update the cart items in the state
      dispatch({
        type: CART_UPDATE_USER_CART,
        payload: response.data,
      });

    } catch (error) {
      console.error("API Error:", error);
    }
  }
};

// Modify your existing savePaymentMethod action in your cart actions file
