import React, { useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Meta from '../components/Meta';
const FAQScreen = () => {
  const [activeQuestion, setActiveQuestion] = useState(null);
  const { t } = useTranslation();

  const toggleQuestion = (questionNumber) => {
    if (activeQuestion === questionNumber) {
      setActiveQuestion(null);
    } else {
      setActiveQuestion(questionNumber);
    }
  };

  return (
    <Meta title={t('faq-title')}>
    <Container>
      <style>
        {`
          .accordion-btn {
            position: relative;
            text-align: left;
            width: 100%;
          }

          .accordion-btn::before,
          .accordion-btn::after {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            font-size: 12px;
            transition: transform 0.5s;
          }

          .accordion-btn::before {
            content: '▼';
          }

          .accordion-btn::after {
            content: '▲';
            transform: translateY(-50%) scaleY(0);
          }

          .accordion-btn.active::before {
            transform: translateY(-50%) scaleY(0);
          }

          .accordion-btn.active::after {
            transform: translateY(-50%);
          }

          .accordion-body {
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.5s;
          }

          .accordion-body.show {
            max-height: 1000px;
          }
        `}
      </style>
      
      <Row className="justify-content-md-center">
        <Col xs={12} md={8}>
          <h1>{t('faq-title')}</h1>
          <p>
          {t('faq-description')}
          </p>
          <Card>
            <Card.Header>
              <Button className={`accordion-btn ${activeQuestion === 0 ? 'active' : ''}`} variant="link" onClick={() => toggleQuestion(0)}>
              {t('faq-q1-q')}
              </Button>
            </Card.Header>
            <div className={`accordion-body ${activeQuestion === 0 ? 'show' : ''}`}>
              <Card.Body>
              {t('faq-q1-a')}
              </Card.Body>
            </div>
          </Card>
          <Card>
            <Card.Header>
              <Button className={`accordion-btn ${activeQuestion === 1 ? 'active' : ''}`} variant="link" onClick={() => toggleQuestion(1)}>
              {t('faq-q2-q')}
              </Button>
            </Card.Header>
            <div className={`accordion-body ${activeQuestion === 1 ? 'show' : ''}`}>
              <Card.Body>
              {t('faq-q2-a')}
              </Card.Body>
            </div>
          </Card>
          <Card>
            <Card.Header>
              <Button className={`accordion-btn ${activeQuestion === 2 ? 'active' : ''}`} variant="link" onClick={() => toggleQuestion(2)}>
              {t('faq-q3-q')}
              </Button>
            </Card.Header>
             <div className={`accordion-body ${activeQuestion === 2 ? 'show' : ''}`}>
              <Card.Body>
              {/* You can <a href="/contact-us">contact us</a> with your questions. We typically respond within 24 hours. */}
              <a href="/contact-us">  {t('faq-q3-a')}</a>
            
              </Card.Body>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
    </Meta>
  );
};

export default FAQScreen;

