import { useState } from "react";
import FormContainer from "../components/FormContainer";
import { useDispatch } from "react-redux";
import { Button, Form, Alert } from "react-bootstrap";
import { requestPasswordReset } from '../actions/userActions';
import { useTranslation } from 'react-i18next';
import Meta from "../components/Meta";
const ResetPasswordScreen = () => {
  const [email, setEmail] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");  // Add a new state for the error message
  
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (!emailRegex.test(email)) {
      setErrorMessage(t('invalid-email-error-message')); // Use translation for the error message
      return;
    }
    dispatch(requestPasswordReset(email));
    setShowAlert(true);
    setIsButtonDisabled(true);
    setErrorMessage(""); // Reset the error message when email is valid
  };

  return (
    <Meta title={t('place-order-screen-shipping-text')}>
    <FormContainer>
      <h1>{t('reset-password-page-title')}</h1>
      {showAlert && (
        <Alert variant="success" onClose={() => setShowAlert(false)} dismissible>
          {t('reset-password-success-link-sent-message')}
        </Alert>
      )}
      {errorMessage && (
        <Alert variant="danger">
          {errorMessage}
        </Alert>
      )}
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="email">
          <Form.Label>{t('email-address-text')}</Form.Label>
          <Form.Control
            type="email"
            placeholder={t('enter-email-text')}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Button
          type="submit"
          className="round"
          style={{ marginTop: '1.5rem' }}
          variant="primary"
          disabled={isButtonDisabled}
        >
          {t('reset-password-send-link-text')}
        </Button>
      </Form>
    </FormContainer>
    </Meta>
  );
};

export default ResetPasswordScreen;

