import React, { useState } from 'react';
import { Container, Row, Col, Form, Button,Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Meta from "../components/Meta";
const ContactUs = () => {
  const [messageSent, setMessageSent] = useState(false);
  const { t } = useTranslation();
  const sendEmail = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const data = {
      name: formData.get('name'),
      email: formData.get('email'),
      message: formData.get('message'),
    };

    const response = await fetch('https://formspree.io/f/meqwpwbb', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      setMessageSent(true);
    } else {
      console.log('Error sending message');
    }

    e.target.reset();
  };

  return (
    <Meta title={t('contact-us-success-message')}>
    <Container>
      <Row className="justify-content-md-center">
        <Col xs={12} md={8}>
          <h1>{t('contact-us-title')}</h1>
          {messageSent ? (
             <Alert variant="success">
             <p> {t('contact-us-success-message')}</p>
           </Alert>
          ) : (
            <p>
             {t('contact-us-description')}
            </p>
          )}
          <Form onSubmit={sendEmail}>
            <Form.Group controlId="name">
              <Form.Label>{t('contact-us-name')}</Form.Label>
              <Form.Control type="text" name="name" required />
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Label>{t('contact-us-email')}</Form.Label>
              <Form.Control type="email" name="email" required />
            </Form.Group>
            <Form.Group controlId="message">
              <Form.Label>{t('contact-us-message')}</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="message"
                required
              />
            </Form.Group>
            <Button className="mt-3 round" variant="primary" type="submit">
            {t('contact-us-button')}
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
    </Meta>
  );
};

export default ContactUs;
