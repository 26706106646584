import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Container } from "react-bootstrap";
import Header from "./components/header";
import { Footer } from "./components/footer";
import HomeScreen from "./screens/HomeScreen";
import ProductsScreen from "./screens/ProductsScreen";
import CartScreen from "./screens/CartScreen";
import RegisterScreen from "./screens/RegisterScreen";
import ProfileScreen from "./screens/ProfileScreen";
import PaymentScreen from "./screens/PaymentScreen";
import PlaceOrderScreen from "./screens/PlaceOrderScreen";
import OrderScreen from "./screens/OrderScreen";
import { LoginRoutes } from "./screens/LoginScreen";
import UserListScreen from "./screens/UserListScreen";
import UserEditScreen from "./screens/UserEditScreen";
import ProductListScreen from "./screens/ProductListScreen";
import ProductEditScreen from "./screens/ProductEditScreen";
import OrderListScreen from "./screens/OrderListScreen";
import ArticleListScreen from "./screens/ArticleListScreen";
import ArticleCreateScreen from "./screens/ArticleCreateScreen";
import ArticleEditScreen from "./screens/ArticleEditScreen";
import ArticleViewScreen from "./screens/ArticleViewScreen";
import AllArticles from "./screens/AllArticles";
import WelcomePage from "./screens/WelcomePage";
import FAQScreen from "./screens/FAQScreen.js";
import AboutUs from "./screens/AboutUsScreen";
import ContactUs from "./components/ContactUs";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ResetPasswordScreen from "./screens/ResetPasswordScreen";
import NewPasswordScreen from "./screens/NewPasswordScreen";

const App = () => {
  return (
    <Router>
      <Header />
      <main className="py-3">
        <Container>
          <Routes>
            <Route path="/login/*" element={<LoginRoutes />} />
            <Route path="/reset-password" element={<ResetPasswordScreen />} />
            <Route path="/reset-password/:token" element={<NewPasswordScreen />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/faq" element={<FAQScreen />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/payment" element={<PaymentScreen />} />
            <Route path="/orders/:id" element={<OrderScreen />} />
            <Route path="/placeorder" element={<PlaceOrderScreen />} />
            <Route path="/register" element={<RegisterScreen />} />
            <Route path="/profile" element={<ProfileScreen />} />
            <Route path="/product/:id" element={<ProductsScreen />} />
            <Route path="/cart/:id" element={<CartScreen />} />
            <Route path="/cart/" element={<CartScreen />} />
            <Route path="/admin/userlist/" element={<UserListScreen />} />
            <Route path="/admin/orderlist/" element={<OrderListScreen />} />
            <Route path="/admin/articlelist" element={<ArticleListScreen />} />
            <Route path="/article/:id" element={<ArticleViewScreen />} />
            <Route path="/articles" element={<AllArticles />} />
            <Route
              path="/admin/article/:id/edit"
              element={<ArticleEditScreen />}
            />
            <Route
              path="/admin/article/create"
              element={<ArticleCreateScreen />}
            />
            ;
            <Route
              path="/admin/productlist/"
              element={<ProductListScreen />}
              exact
            />
            <Route
              path="/admin/productlist/:pageNumber"
              element={<ProductListScreen />}
              exact
            />
            <Route
              path="/admin/product/:id/edit"
              element={<ProductEditScreen />}
            />
            <Route path="/admin/user/:id/edit" element={<UserEditScreen />} />
            <Route
              path="/all-products/search/:keyword/page/:pageNumber"
              element={<HomeScreen />}
              exact
            />
            <Route
              path="/all-products/search/:keyword"
              element={<HomeScreen />}
            />
            <Route
              path="/all-products/page/:pageNumber"
              element={<HomeScreen />}
              exact
            />
            <Route
              path="/all-products/category/:categoryName"
              element={<HomeScreen />}
              exact
            />
            <Route path="/all-products/" element={<HomeScreen />} exact />
            <Route path="/" element={<WelcomePage />} />
          </Routes>
        </Container>
      </main>
      <Footer />
    </Router>
  );
};

export default App;
