
/* eslint-disable no-multi-str */
import React, { useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../components/Loader";
import { Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import FormContainer from "../components/FormContainer";
import { createArticle } from "../actions/articleActions";
import { Editor } from '@tinymce/tinymce-react';



const ArticleCreateScreen = () => {
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [content, setContent] = useState("");
  const [author, setAuthor] = useState("");
  const [image, setImage] = useState("");
  const [uploading, setUploading] = useState(false);
  const [tags, setTags] = useState("");
  const [nameHe, setNameHe] = useState("");
  const [contentHe, setContentHe] = useState("");
  const [categoryHe, setCategoryHe] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();


const submitHandler = async (e) => {
    e.preventDefault();
    try {
      await dispatch(
        createArticle({
          name,
          category,
          content,
          author,
          image,
          tags: tags.split(",").map((tag) => tag.trim()), 
          nameHe,
          contentHe,
          categoryHe
        })
      );
      navigate("/admin/articlelist");
    } catch (error) {
      console.error("Error during article creation:", error);
    }
  };
  

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post("/api/upload", formData, config);

    
setImage(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  

  
  return (
    <>
      <Link to="/admin/articlelist" className="btn btn-light my-3">
        Go Back
      </Link>
      <FormContainer>
        <h1>Create Article</h1>
        <Form onSubmit={submitHandler}>
          <Form.Group controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="name"
              placeholder="Enter name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="category">
            <Form.Label>Category</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="content">
          <Form.Label>Content</Form.Label>
          <Editor
            apiKey="f0qen3vi2edeenyeqvrzg5wvvc7o2ysgs8desk0ndipd9oup"
            initialValue={content}
            init={{
              height: 300,
              menubar: false,
              plugins :[
                'advlist',
                'autolink',
                'lists',
                'link',
                'image',
                'charmap',
                'print',
                'preview',
                'anchor',
                'searchreplace',
                'visualblocks',
                'code',
                'fullscreen',
                'insertdatetime',
                'media',
                'table',
                'paste',
                'imagetools',
                'wordcount'
              ],
              
              toolbar:
                "undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | image | code | help",
                image_advtab: true,
            }}
            onEditorChange={(newContent) => setContent(newContent)}
          />
        </Form.Group>


          <Form.Group controlId="author">
            <Form.Label>Author</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter author name"
              value={author}
              onChange={(e) => setAuthor(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId="image">
  <Form.Label>Image</Form.Label>
  <Form.Control
    type="text"
    placeholder="Enter image url"
    value={image}
    onChange={(e) => setImage(e.target.value)}
  ></Form.Control>
  <Form.Group controlId="image-file">
    <Form.Label>Choose File</Form.Label>
    <Form.Control
      type="file"
      custom="true"
      onChange={uploadFileHandler}
    ></Form.Control>
  </Form.Group>
  {uploading && <Loader />}
</Form.Group>
<Form.Group controlId="tags">
  <Form.Label>Tags</Form.Label>
  <Form.Control
    type="text"
    placeholder="Enter tags separated by commas"
    value={tags}
    onChange={(e) => setTags(e.target.value)}
  ></Form.Control>
</Form.Group>




<Form.Group controlId="nameHe">
    <Form.Label>Name (Hebrew)</Form.Label>
    <Form.Control
        type="name"
        placeholder="Enter Hebrew name"
        value={nameHe}
        onChange={(e) => setNameHe(e.target.value)}
    ></Form.Control>
</Form.Group>

<Form.Group controlId="contentHe">
    <Form.Label>Content (Hebrew)</Form.Label>
    <Editor
        apiKey="f0qen3vi2edeenyeqvrzg5wvvc7o2ysgs8desk0ndipd9oup"
        initialValue={contentHe}
        init={{
          height: 300,
          menubar: false,
          plugins :[
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            // 'print',
            'preview',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            // 'paste',
            // 'image-tools',
            'wordcount'
          ],
          toolbar:
            "undo redo | formatselect | bold italic backcolor | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | image | code | help",
            image_advtab: true,
            // paste_data_images: true,
            // images_upload_handler: function (blobInfo, success, failure) {
            //   success("data:" + blobInfo.blob().type + ";base64," + blobInfo.base64());
            // },
        }}
        onEditorChange={(newContent) => setContentHe(newContent)}

      />
</Form.Group>

<Form.Group controlId="categoryHe">
    <Form.Label>Category (Hebrew)</Form.Label>
    <Form.Control
        type="text"
        placeholder="Enter Hebrew category"
        value={categoryHe}
        onChange={(e) => setCategoryHe(e.target.value)}
    ></Form.Control>
</Form.Group>


<Button type="submit" variant="primary">
  Create
</Button>
</Form>
      </FormContainer>
     </>
 );
};

 export default ArticleCreateScreen;        
             

