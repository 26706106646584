import React, { useState } from "react";
import { Form, Button, Col } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "../components/FormContainer";
import CheckoutSteps from "../components/CheckoutSteps";
import { savePaymentMethod } from "../actions/cartActions";
import { useTranslation } from 'react-i18next';
import Meta from "../components/Meta";
const PaymentScreen = ({ history }) => {
  const navigate = useNavigate();
  const cart = useSelector((state) => state.cart);
  const { shippingAddress } = cart;
  const { t } = useTranslation();
  
  if (!shippingAddress.address) {
    navigate("/shipping");
  }

  const [paymentMethod, setPaymentMethod] = useState("PayPal");

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(savePaymentMethod(paymentMethod));
    navigate("/placeorder");
  };

  return (
    <Meta title={t('payment-method-page-title')}>
    <FormContainer>
      <CheckoutSteps step1 step2 step3 />
      <h1> {t('payment-method-page-title')}</h1>
      <Form onSubmit={submitHandler}>
        <Form.Group>
          <Form.Label as="legend"> {t('selet-method-text')}</Form.Label>
          <Col>
            <Form.Check
              type="radio"
              label={t('payment-method-label')}
              id="PayPal"
              name="paymentMethod"
              value="PayPal"
              checked
              onChange={(e) => setPaymentMethod(e.target.value)}
            ></Form.Check>
            {/* <Form.Check
              type='radio'
              label='Stripe'
              id='Stripe'
              name='paymentMethod'
              value='Stripe'
              onChange={(e) => setPaymentMethod(e.target.value)}
            ></Form.Check> */}
          </Col>
        </Form.Group>

        <Button style={{ marginTop: "13px" }}  className="round" type="submit" variant="primary">
        {t('continue-button')}
        </Button>
      </Form>
    </FormContainer>
    </Meta>
  );
};

export default PaymentScreen;
