import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { translateText } from "../TranslateText";
import i18n from 'i18next';
import Meta from "../components/Meta";

const ArticleViewScreen = () => {
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [translatedArticleAuthor, setTranslatedArticleAuthor] = useState("");
  const contentRef = useRef();
  const navigate = useNavigate();
  const language = useSelector((state) => state.language.language);
  const [isLanguageLoaded, setIsLanguageLoaded] = useState(false);

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const fetchArticle = async () => {
      const { data } = await axios.get(`/api/articles/${id}`);
      setArticle(data);
    };
    fetchArticle();
  }, [id]);

  useEffect(() => {
    if (language === 'he') {
      console.log("he")
      i18n.changeLanguage('he').then(() => {
        setIsLanguageLoaded(true);
      });
    } else {
      i18n.changeLanguage('en').then(() => {
        console.log("en")
        setIsLanguageLoaded(true);
      });
    }
  }, [language]);
  
  useEffect(() => {
    if (article && isLanguageLoaded) {
      if (language === "he") {
        translateText(article.author, "he")
          .then((translatedAuthor) => setTranslatedArticleAuthor(translatedAuthor))
          .catch((err) => console.error(err));
      } else {
        setTranslatedArticleAuthor(article.author);
      }
    }
  }, [article, language, isLanguageLoaded]);

  useEffect(() => {
    if (!article) return;

    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible");
        } else {
          entry.target.classList.remove("visible");
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );
    const images = contentRef.current.querySelectorAll("img.fade-in");

    images.forEach((img) => observer.observe(img));

    return () => {
      images.forEach((img) => observer.unobserve(img));
    };
  }, [article]);

  if (!article || !isLanguageLoaded) {
    return <h2>Loading...</h2>;
  }

  return (
    <Meta title={language === 'he' ? article.nameHe : article.name}>
    <Container>
      <Link className="btn btn-light my-3" to="#" onClick={goBack}>
       {i18n.t('go-back-button')}
      </Link>
      <Row className="justify-content-center">
        <Col md={8} className="text-center">
          <Card.Img
            src={article.image}
            alt={language === 'he' ? article.nameHe : article.name}
            style={{ maxWidth: "80%", height: "auto", objectFit: "cover" }}
          />
        </Col>
      </Row>
      <Row className="justify-content-center mt-4">
        <Col md={8} className="text-center">
          <h1>{language === 'he' ? article.nameHe : article.name}</h1>
          <h6>
            {i18n.t('article-author')}: <span>{translatedArticleAuthor}</span> |   {i18n.t('article-category')}:{" "}
            <span>{language === 'he' ? article.categoryHe : article.category}</span> | {i18n.t('article-date')}:{" "}
            <span> {new Date(article.date).toLocaleDateString("en-GB")}</span>
          </h6>
          <hr />
        </Col>
      </Row>
      <Row id="article-content">
        <Col md={{ span: 8, offset: 2 }}>
          <div
            className="mt-4 p-2"
            ref={contentRef}
            dangerouslySetInnerHTML={{ __html: language === 'he' ? article.contentHe : article.content }}
          ></div>
        </Col>
      </Row>
    </Container>
    </Meta>
  );
};

export default ArticleViewScreen;

