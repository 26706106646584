import React from 'react'
import { Nav } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap'
import LanguageSwitcher from './LanguageSwitcher'
import { useTranslation } from 'react-i18next';

const CheckoutSteps = ({ step1, step2, step3, step4 }) => {
  const { t } = useTranslation();
  const language = useSelector(state => state.language.language);
  return (
    <div className="d-flex flex-nowrap mb-4 justify-content-center">
      <Nav.Item>
        {step1 ? (
          <LinkContainer to='/login'>
            <Nav.Link>{t('sign-in-text')}</Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled>{t('sign-in-text')}</Nav.Link>
        )}
      </Nav.Item>

      <Nav.Item>
        {step2 ? (
          <LinkContainer to='/login/shipping'>
            <Nav.Link>{t('place-order-screen-shipping-text')}</Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled>{t('place-order-screen-shipping-text')}</Nav.Link>
        )}
      </Nav.Item>

      <Nav.Item>
        {step3 ? (
          <LinkContainer to='/payment'>
            <Nav.Link>{t('payment-text')}</Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled>{t('payment-text')}</Nav.Link>
        )}
      </Nav.Item>

      <Nav.Item>
        {step4 ? (
          <LinkContainer to='/placeorder'>
            <Nav.Link>{t('place-order-button')}</Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled>{t('place-order-button')}</Nav.Link>
        )}
      </Nav.Item>
    </div>
  )
}

export default CheckoutSteps