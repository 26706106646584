import React from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

const Meta = ({ title, description, keywords, children }) => {
  const language = useSelector(state => state.language?.language); // using optional chaining to prevent error when state.language is undefined
  const location = useLocation(); 
  const hebrewKeywords = 'הדפסי אמנות, הדפסי אמנות מותאמים אישית, הדפסי אמנות מותאמים אישית, אמנות קיר, כרזות אמנות, יצירות אמנות למכירה, הדפסי אמנות, קישוטי אמנות, הדפסי קנבס, הדפסים ממוסגרים, מתנות אמנות, יצירות אמנות ייחודיות, הדפסי אמנות במחירים סבירים, פריטי אספנות, אמנות מודרנית הדפסים, אמנות עכשווית, אומנות עיצוב הבית, אומנות לעיצוב פנים, גלריית אמנות מקוונת, חובבי אמנות, חובבי אמנות, מבטאים אומנותיים לבית, עיצוב קיר מותאם אישית, השראה לאמנות, רעיונות לעיצוב אמנות, מדריך לסגנון אמנות, מגמות אמנות, חנות כלי אמנות, אמנות הדפסות מקוונות,אמנות לכל חדר,הדפסים במהדורה מוגבלת,אומנות לחלל משרדים,אומנות לחללי מגורים,אומנות לעיצוב חדרי שינה,אומנות לעיצוב מטבח,אומנות לעיצוב חדרי אמבטיה,אומנות לחדרי ילדים,הדפסי אמנות נוף,הדפסי אומנות מופשטת ,אמנות בהשראת טבע, הדפסי אמנות בעלי חיים, הדפסי אמנות בוטנית, הדפסי אמנות דיוקנאות, אמנות בשחור-לבן, הדפסי אמנות צבעוניים, אמנות לאירועי מתנות, אמנות לאירועים מיוחדים, מתנות אמנות מותאמות אישית, אמנות לחתונות, אמנות לימי הולדת';
  const hebrewDescription = 'גלו הדפסי אמנות משובחים ויצירות אמנות מותאמות אישית. שדרגו את החלל שלכם עם עיצובים מדהימים ונותני השראה.';
  

  const currentKeywords = language === 'he' ? hebrewKeywords : keywords;
  const currentDescription = language === 'he' ? hebrewDescription : description;
  const currentUrl = location.pathname;
  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name='description' content={currentDescription} />
        <meta name='keyword' content={currentKeywords} />
        <link rel="canonical" href={currentUrl} /> 
      </Helmet>
      {children}
    </div>
  )
}

Meta.defaultProps = {
  title: 'Welcome to Artifex',
  description: 'Discover exquisite art prints and custom artwork. Elevate your space with curated designs that inspire.',
  keywords: "art prints,custom art prints,personalized art prints,wall art,art posters,artwork for sale,fine art prints,art decorations,canvas prints,framed prints,art gifts,unique art pieces,affordable art prints,art collectibles,modern art prints,contemporary art,home decor art,art for interior design,art gallery online,art enthusiasts,art lovers,artistic home accents,customized wall decor,art inspiration,art decor ideas,art style guide,art trends,artware shop,art prints online,art for every room,limited edition prints,art for office spaces,art for living spaces,art for bedroom decor,art for kitchen decor,art for bathroom decor,art for kids' rooms,landscape art prints,abstract art prints,nature-inspired art,animal art prints,botanical art prints,portrait art prints,black and white art,colorful art prints,art for gifting occasions,art for special events,personalized art gifts,art for weddings,art for birthdays"
}

export default Meta
