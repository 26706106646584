
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from 'react-router-dom'
import { Button, Row, Col, Card, ListGroup, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import CheckoutSteps from "../components/CheckoutSteps";
import { createOrder } from "../actions/orderActions";
import { ORDER_CREATE_RESET } from '../constants/orderConstants'
import { USER_DETAILS_RESET } from '../constants/userConstants'
import { useTranslation } from 'react-i18next';
import { getRate } from '../currencyExchange';
import Meta from "../components/Meta";
const PlaceOrderScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cart = useSelector((state) => state.cart);
    const { t } = useTranslation();
    const language = useSelector((state) => state.language.language);
    const [exchangeRate, setExchangeRate] = useState(1);
    const [currencySymbol, setCurrencySymbol] = useState("$");

    cart.itemsPrice = cart.cartItems.reduce((acc,item)=> acc + (item.price * item.qty), 0)
    const addDecimals = (num) => {
        return (Math.round(num * 100) / 100).toFixed(2)
    }
    cart.shippingPrice = addDecimals(cart.itemsPrice > 100 ? 0 : 100)
    cart.taxPrice = addDecimals(Number((0.175 * cart.itemsPrice).toFixed(2)))
    cart.totalPrice = (
        Number(cart.itemsPrice) +
        Number(cart.shippingPrice) +
        Number(cart.taxPrice)
    ).toFixed(2)

    const orderCreate = useSelector((state) => state.orderCreate)
    const { order, success, error } = orderCreate

    useEffect(() => {
        const fetchExchangeRate = async () => {
            let rate = await getRate();
            setExchangeRate(rate);
        };
        setCurrencySymbol(language === "he" ? "₪" : "$");
        fetchExchangeRate();
        if (success) {
            navigate(`/orders/${order._id}`)
            dispatch({ type: USER_DETAILS_RESET })
            dispatch({ type: ORDER_CREATE_RESET })
        }
        // eslint-disable-next-line
    }, [success, cart.cartItems, language])

    const placeOrderHandler = () => {
        dispatch(
            createOrder({
                orderItems: cart.cartItems,
                shippingAddress: cart.shippingAddress,
                paymentMethod: cart.paymentMethod,
                itemsPrice: cart.itemsPrice,
                shippingPrice: cart.shippingPrice,
                taxPrice: cart.taxPrice,
                totalPrice: cart.totalPrice,
            })
        )
    }

    const displayPrice = (price) => {
        const convertedPrice = (price * (language === 'he' ? 1 : exchangeRate)).toFixed(2);
        return `${currencySymbol}${convertedPrice}`;
    }





  return (
    <>
     <Meta title={t('place-order-screen-shipping-text')}>
      <CheckoutSteps step1 ste2 step3 step4 />
      <Row>
        <Col md={8}>
          <ListGroup variant="flush">
            {" "}
            <ListGroup.Item>
              <h2>  {t('place-order-screen-shipping-text')}</h2>
              <p>
                <strong>  {t('place-order-screen-address-text')}</strong>
                {cart.shippingAddress.address}, {cart.shippingAddress.city}{" "}
                {cart.shippingAddress.postalCode},{" "}
                {cart.shippingAddress.country}
              </p>
            </ListGroup.Item>
            <ListGroup.Item>
              <h2>  {t('payment-method-page-title')}</h2>
              <strong>  {t('place-order-screen-method-text')} </strong>
              {cart.paymentMethod}
            </ListGroup.Item>
            <ListGroup.Item>
              <h2>  {t('place-order-screen-order-items-text')}</h2>
              {cart.cartItems.length === 0 ? (
                <Message>  {t('cart-empty-text')}</Message>
              ) : (
                <ListGroup variant="flush">
                  {cart.cartItems.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col md={1}>
                          <Image
                            src={item.image}
                            alt={language === 'he' ? item.nameHe : item.name}
                            fluid
                            rounded
                          />
                        </Col>
                        <Col>
                          <Link to={`/product/${item.product}`}>
                            {language === 'he' ? item.nameHe : item.name}
                          </Link>
                        </Col>
                        <Col md={4}>
                        {language === "he" ? (
                            <>
                              {displayPrice(item.qty * item.price)} ={" "}
                              {displayPrice(item.price)}{"x"}{item.qty}
                            </>
                          ) : (
                            <>
                              {item.qty}{' x '}{displayPrice(item.price)} ={" "}
                              {displayPrice(item.qty * item.price)}
                            </>
                          )}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={4}>
          <Card>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <h2>  {t('place-order-screen-order-summery-text')}</h2>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>  {t('place-order-screen-items-text')}</Col>
                  <Col>{displayPrice(cart.itemsPrice)}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>  {t('place-order-screen-shipping-text')}</Col>
                  <Col>{displayPrice(cart.shippingPrice)}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>  {t('place-order-screen-tax-text')}</Col>
                  <Col>{displayPrice(cart.taxPrice)}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>  {t('place-order-screen-subtotal-text')}</Col>
                  <Col>{displayPrice(cart.totalPrice)}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                {error && <Message variant="danger">{error}</Message>}
              </ListGroup.Item>
              <ListGroup.Item style={{display:'flex', justifyContent:'center'}}>
                <Button
                  type="button"
                  className="btn-block round"
                  disabled={cart.cartItems === 0}
                  onClick={placeOrderHandler}
                >
                 {t('place-order-button')}
                </Button>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
      </Row>
      </Meta>
    </>
  );
};

export default PlaceOrderScreen;

