
import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { Link, useParams } from 'react-router-dom';
import VideoBackground from '../components/VideoBackground.js';
import ProductCarousel from '../components/ProductCarousel.js';
import Meta from '../components/Meta.js';
import ArticleSwiper from '../components/ArticleSwiper.js';
import axios from 'axios';
import { useTranslation } from 'react-i18next';


const WelcomePage = () => {
  const { keyword } = useParams();
  const [articles, setArticles] = useState([]);
  const { t } = useTranslation();
  const videoSrc = `${process.env.PUBLIC_URL}/videos/welcome.mp4`;
  const pageStyle = {
    color: 'white',
    opacity: 0,
    transition: 'opacity 0.8s ease-in-out',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '50vh',
    paddingTop: '0px',
    marginTop: '-1rem',
  };

  useEffect(() => {
    const fetchArticles = async () => {
      const { data } = await axios.get('/api/articles');
      setArticles(data);
    };

    fetchArticles();

    const timerId = setTimeout(() => {
      const page = document.querySelector('.welcome-page');
      if (page) {
        page.style.opacity = 1;
      }
    }, 100);

    return () => clearTimeout(timerId);
  }, []);

  return (
    <>
 
      <Meta />
      <div className="welcome-page" style={pageStyle}>
        <VideoBackground videoSrc={videoSrc} />
        <h1 style={{ color: 'white',textAlign:'center' }}>{t('welcome')}</h1>
        <p style={{textAlign:'center'}}>{t('subgreeting')}</p>
        <Link to="/all-products">
          <Button className="round" variant="primary">{t('shopNowButton')}</Button>
        </Link>
      </div>
      <div>
        {!keyword ? (
          <ProductCarousel />
        ) : (
          <Link to="/" className="btn btn-light round">
            Go Back
          </Link>
        )}
      </div>
      <div>
        <ArticleSwiper articles={articles} />
        <div className="articles-link">
          <Link className="generic-css white-colored" to="/articles">
           {t('article-see-all')}
          </Link>
        </div>
      </div>

    </>
  );
};

export default WelcomePage;


