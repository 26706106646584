// import {
//   legacy_createStore as createStore,
//   combineReducers,
//   applyMiddleware,
// } from "redux";
// import thunk from "redux-thunk";
// import { composeWithDevTools } from "redux-devtools-extension";
// import {
//   productTopRatedReducer,productListReducer,productReviewCreateReducer,
//   productDetailsReducer,productDeleteReducer,productCreateReducer,productUpdateReducer, productSimilarReducer
// } from "./reducers/productReducers";
// import { cartReducer } from "./reducers/cartReduces";
// import { articleCreateReducer, articleDetailsReducer, articleUpdateReducer, articleDeleteReducer, articleListReducer } from './reducers/articleReducer';
// import {checkOtpEnabledReducer,userUpdateReducer,userDeleteReducer,userListReducer,userLoginReducer,userRegisterReducer,userDetailsReducer,userUpdateProfileReducer,userPasswordResetReducer} from "./reducers/userReducers"
// import {orderListReducer,orderListMyReducer,orderCreateReducer,orderDetailsReducer,orderPayReducer,orderDeliverReducer} from './reducers/orderReducers'
// import {languageReducer} from './reducers/languageReducer';

// const reducer = combineReducers({
//   productList: productListReducer,
//   productDetails: productDetailsReducer,
//   productDelete: productDeleteReducer,
//   productUpdate: productUpdateReducer,
//   productCreate: productCreateReducer,
//   productReviewCreate: productReviewCreateReducer,
//   productTopRated: productTopRatedReducer,
//   productSimilar:productSimilarReducer,
//   cart: cartReducer,
//   userLogin: userLoginReducer,
//   userRegister: userRegisterReducer,
//   userDetails: userDetailsReducer,
//   userUpdateProfile: userUpdateProfileReducer,
//   userUpdate: userUpdateReducer,
//   orderCreate: orderCreateReducer,
//   orderDetails: orderDetailsReducer,
//   orderPay: orderPayReducer,
//   orderDeliver: orderDeliverReducer,
//   orderListMy: orderListMyReducer,
//   orderList: orderListReducer,
//   userList: userListReducer,
//   userOtpEnabled: checkOtpEnabledReducer,
//   userDelete: userDeleteReducer,
//   articleCreate: articleCreateReducer,
//   articleDetails: articleDetailsReducer,
//   articleUpdate: articleUpdateReducer,
//   articleDelete: articleDeleteReducer,
//   articleList: articleListReducer,
//   passwordReset: userPasswordResetReducer,
//   language:languageReducer
// });


// const cartItemsFromStorage = localStorage.getItem("cartItems")
//   ? JSON.parse(localStorage.getItem("cartItems"))
//   : [];

//   const userInfoFromStorage = localStorage.getItem("userInfo")
//   ? JSON.parse(localStorage.getItem("userInfo"))
//   : null;

//   const shippingAddressFromStorage = localStorage.getItem("shippingAddress")
//   ? JSON.parse(localStorage.getItem("shippingAddress"))
//   : {};

// const initialState = {
//     cart:{cartItems:cartItemsFromStorage,shippingAddress:shippingAddressFromStorage},
//     userLogin: {userInfo: userInfoFromStorage}
// };

// const middleWare = [thunk];

// const store = createStore(
//   reducer,
//   initialState,
//   composeWithDevTools(applyMiddleware(...middleWare))
// );

// export default store;


import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  productTopRatedReducer,
  productListReducer,
  productReviewCreateReducer,
  productDetailsReducer,
  productDeleteReducer,
  productCreateReducer,
  productUpdateReducer,
  productSimilarReducer,
} from "./reducers/productReducers";
import { cartReducer } from "./reducers/cartReduces";
import {
  articleCreateReducer,
  articleDetailsReducer,
  articleUpdateReducer,
  articleDeleteReducer,
  articleListReducer,
} from "./reducers/articleReducer";
import {
  checkOtpEnabledReducer,
  userUpdateReducer,
  userDeleteReducer,
  userListReducer,
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userPasswordResetReducer,
} from "./reducers/userReducers";
import {
  orderListReducer,
  orderListMyReducer,
  orderCreateReducer,
  orderDetailsReducer,
  orderPayReducer,
  orderDeliverReducer,
} from "./reducers/orderReducers";
import { languageReducer } from "./reducers/languageReducer";

const reducer = combineReducers({
  productList: productListReducer,
  productDetails: productDetailsReducer,
  productDelete: productDeleteReducer,
  productUpdate: productUpdateReducer,
  productCreate: productCreateReducer,
  productReviewCreate: productReviewCreateReducer,
  productTopRated: productTopRatedReducer,
  productSimilar: productSimilarReducer,
  cart: cartReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userUpdate: userUpdateReducer,
  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  orderPay: orderPayReducer,
  orderDeliver: orderDeliverReducer,
  orderListMy: orderListMyReducer,
  orderList: orderListReducer,
  userList: userListReducer,
  userOtpEnabled: checkOtpEnabledReducer,
  userDelete: userDeleteReducer,
  articleCreate: articleCreateReducer,
  articleDetails: articleDetailsReducer,
  articleUpdate: articleUpdateReducer,
  articleDelete: articleDeleteReducer,
  articleList: articleListReducer,
  passwordReset: userPasswordResetReducer,
  language: languageReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["language","cart"], // only language will be persisted
};

const persistedReducer = persistReducer(persistConfig, reducer);

const cartItemsFromStorage = localStorage.getItem("cartItems")
  ? JSON.parse(localStorage.getItem("cartItems"))
  : [];

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const shippingAddressFromStorage = localStorage.getItem("shippingAddress")
  ? JSON.parse(localStorage.getItem("shippingAddress"))
  : {};

const initialState = {
  cart: { cartItems: cartItemsFromStorage, shippingAddress: shippingAddressFromStorage },
  userLogin: { userInfo: userInfoFromStorage },
};

const middleWare = [thunk];

const composeEnhancers = composeWithDevTools || compose;

const store = createStore(
  persistedReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middleWare))
);

export const persistor = persistStore(store);

export default store;
