import axios from "axios";

let lastFetch = null;
let rate = null;

export const getRate = async () => {
  // get the current date
  let today = new Date().toISOString().split('T')[0];

  // check if the rates are fetched today already
  if (lastFetch === today && rate) {
    return rate;
  }

  try {
    // replace 'YOUR_API_KEY' with your actual API key
    let res = await axios.get('https://v6.exchangerate-api.com/v6/1df819c0933173da85cc2a41/latest/ILS');
    
    if (res.data && res.data.conversion_rates && res.data.conversion_rates.USD) {
      rate = res.data.conversion_rates.USD;
      lastFetch = today;
      return rate;
    } else {
      throw new Error('Unable to fetch exchange rate');
    }

  } catch (error) {
    console.error(error);
    return null;
  }
}

