import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "../components/FormContainer";
import CheckoutSteps from "../components/CheckoutSteps";
import { saveShippingAddress } from "../actions/cartActions";
import { useTranslation } from 'react-i18next';
import Meta from "../components/Meta";
const ShippingScreen = () => {
    const navigate = useNavigate();
    const cart = useSelector((state) => state.cart)
    const { shippingAddress } = cart
    const [address, setAddress] = useState(shippingAddress.address)
    const [city, setCity] = useState(shippingAddress.city)
    const [postalCode, setPostalCode] = useState(shippingAddress.postalCode)
    const [country, setCountry] = useState(shippingAddress.country)
    const { t } = useTranslation();
  
    const dispatch = useDispatch()
  
    const submitHandler = (e) => {
      e.preventDefault()
      dispatch(saveShippingAddress({ address, city, postalCode, country }))
      navigate('/payment')
    }
  
    return (
      <Meta title={t('place-order-screen-shipping-text')}>
      <FormContainer>
        <CheckoutSteps step1 step2 />
        <h1>{t('place-order-screen-shipping-text')}</h1>
        <Form onSubmit={submitHandler}>
          <Form.Group controlId='address'>
            <Form.Label>{t('place-order-screen-address-text')}</Form.Label>
            <Form.Control
              type='text'
              placeholder={t('shipping-screen-enter-address')}
              value={address}
              required
              onChange={(e) => setAddress(e.target.value)}
            ></Form.Control>
          </Form.Group>
  
          <Form.Group controlId='city'>
            <Form.Label>{t('shipping-screen-city-text')}</Form.Label>
            <Form.Control
              type='text'
              placeholder={t('shipping-screen-enter-city-text')}
              value={city}
              required
              onChange={(e) => setCity(e.target.value)}
            ></Form.Control>
          </Form.Group>
  
          <Form.Group controlId='postalCode'>
            <Form.Label>{t('shipping-screen-postal-code-text')}</Form.Label>
            <Form.Control
              type='text'
              placeholder={t('shipping-screen-enter-postal-code-text')}
              value={postalCode}
              required
              onChange={(e) => setPostalCode(e.target.value)}
            ></Form.Control>
          </Form.Group>
  
          <Form.Group controlId='country'>
            <Form.Label>{t('shipping-screen-country-text')}</Form.Label>
            <Form.Control
              type='text'
              placeholder={t('shipping-screen-enter-country-text')}
              value={country}
              required
              onChange={(e) => setCountry(e.target.value)}
            ></Form.Control>
          </Form.Group>
  
          <Button className="round" type='submit' variant='primary'>
           {t('continue-button')}
          </Button>
        </Form>
      </FormContainer>
      </Meta>
    )
  }
  
  export default ShippingScreen