import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import Article from "../components/Article";
import { useTranslation } from 'react-i18next';
import Meta from "../components/Meta";

const AllArticles = () => {
  const [articles, setArticles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const { t } = useTranslation();
  const language = useSelector((state) => state.language.language);

  useEffect(() => {
    const fetchArticles = async () => {
      const { data } = await axios.get("/api/articles");
      setArticles(data);
      const uniqueCategories = Array.from(
        new Set(data.map((article) => language === 'he' ? article.categoryHe : article.category))
      ).sort();
      console.log(uniqueCategories)
      setCategories(uniqueCategories);
    };
  
    fetchArticles();
  
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, [language]);
  
  
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  return (
    <Meta title={selectedCategory === "" ? t('all-articles-text') : selectedCategory}>
    <div>
      <h2 className="generic-css">
        {selectedCategory === "" ? t('all-articles-text') : selectedCategory}
      </h2>
      <Link to="/" className="btn btn-light">
       {t('go-back-button')}
      </Link>
      <div>
      <div className="generic-css">
        <div className="mt-3" style={{ width: "200px", textAlign: "center" }}>
          <label htmlFor="categoryFilter" className="form-label">
           {t('filter-by-category-text')}
          </label>
          <select
            className="form-select"
            id="categoryFilter"
            value={selectedCategory}
            onChange={handleCategoryChange}
          >
            <option value="">{t('all-categories-text')}</option>
            {categories.map((category, index) => (
              <option key={index} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="article-list d-flex flex-wrap justify-content-center view-articles-section">
      {articles.map((article) => (
  <div
    key={article._id}
    className="article-item m-3"
    style={{
      width: "280px",
      height: "500px",
      display:
        selectedCategory === "" || 
        selectedCategory === (language === 'he' ? article.categoryHe : article.category)
          ? "block"
          : "none",
    }}
  >
    <Article article={article} />
  </div>
))}
      </div>

      </div>
    </div>
    </Meta>
  );
};

export default AllArticles;
