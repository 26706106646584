
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  Row,
  Col,
  Image,
  ListGroup,
  Card,
  Button,
  Form,
} from "react-bootstrap";
import Rating from "../components/Rating";
import {
  listProductDetails,
  createProductReview,
  listSimilarProducts,
} from "../actions/productActions";
import Loader from "../components/Loader";
import Message from "../components/Message";
import Meta from "../components/Meta";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import { translateText } from "../TranslateText";
import { useTranslation } from 'react-i18next';
import { getRate } from "../currencyExchange";


const ProductsScreen = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [qty, setQty] = useState(1);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const language = useSelector(state => state.language.language);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const productReviewCreate = useSelector((state) => state.productReviewCreate);
  const {
    success: successProductReview,
    loading: loadingProductReview,
    error: errorProductReview,
  } = productReviewCreate;

  const productSimilar = useSelector((state) => state.productSimilar);
  const {
    loading: loadingSimilar,
    error: errorSimilar,
    products: similarProducts,
  } = productSimilar;



  useEffect(() => {
    dispatch(listProductDetails(params.id));
    dispatch(listSimilarProducts(params.id));
  }, [dispatch, params.id]);

  useEffect(() => {
  }, [similarProducts, product, language]);


  const addToCartHandler = () => {
    if (userInfo) {
      navigate(`/cart/${params.id}?qty=${qty}`);
    } else {
      navigate('/login');
    }
  };
  

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      createProductReview(params.id, {
        rating,
        comment,
      })
    );
  };

  useEffect(() => {
  }, [similarProducts, product, language]);


  const [exchangeRate, setExchangeRate] = useState(null);
  const [currencySymbol, setCurrencySymbol] = useState("$");

  useEffect(() => {
    const fetchExchangeRate = async () => {
      let rate = await getRate();
      setExchangeRate(rate);
    };
    setCurrencySymbol(language === "he" ? "₪" : "$");
    fetchExchangeRate();
  }, [language]);  

  const displayPrice = (price) => {
    if(price){
      return (language === 'he' ? price : (price * exchangeRate)).toFixed(2);
    }
  
  }


  return (
    <>
    <Link className="btn btn-light my-3" to="/">
    {t('go-back-button')}
    </Link>
    {loading ? (
      <Loader />
    ) : error ? (
      <Message variant="danger">{error}</Message>
    ) : (
      <>
        <Meta title={language === 'he' ? product.nameHe : product.name} />
        <Row>
          <Col md={6}>
            <Image src={product.image} alt={language === 'he' ? product.nameHe : product.name} fluid />
          </Col>
          <Col md={3}>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <h3> {language === 'he' ? product.nameHe : product.name}</h3>
              
              </ListGroup.Item>
              <ListGroup.Item>
                <Rating
                  value={product.rating}
                  text={`${product.numReviews} ${t('reviews-text')}`}
                />
              </ListGroup.Item>
              <ListGroup.Item>{t('price-text')}: {currencySymbol}{displayPrice(product.price)}</ListGroup.Item>
              <ListGroup.Item>
              {t('description-text')}: {language === 'he' ? product.nameHe : product.name}
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col md={3}>
            <Card>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <Row>
                    <Col>{t('price-text')}:</Col>
                    <Col>
                      <strong>{currencySymbol}{displayPrice(product.price)}</strong>
                    </Col>
                  </Row>
                </ListGroup.Item>

                <ListGroup.Item>
                  <Row>
                    <Col>{t('status-text')}:</Col>
                    <Col>
                      {product.countInStock > 0 ? t('in-stock') : t('out-of-stock')}
                    </Col>
                  </Row>
                </ListGroup.Item>

                {product.countInStock > 0 && (
                  <ListGroup.Item>
                    <Row>
                      <Col>{t('qty-text')}</Col>
                      <Col>
                        <Form.Control
                          as="select"
                          value={qty}
                          onChange={(e) => setQty(e.target.value)}
                        >
                          {[...Array(product.countInStock).keys()].map(
                            (x) => (
                              <option key={x + 1} value={x + 1}>
                                {x + 1}
                              </option>
                            )
                          )}
                        </Form.Control>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                )}

                <ListGroup.Item className="generic-css">
                  <Button
                    onClick={addToCartHandler}
                    className="btn-block round "
                    type="button"
                    disabled={product.countInStock === 0}
                  >
                    {t('add-to-cart-button')}
                  </Button>
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </Col>
        </Row>
        <Row style={{ marginTop: "3rem", marginBottom: "3rem" }}>
          <Col  md={12}>
            <h2>{t('you-might-also-like-text')}</h2>
            {loadingSimilar ? (
              <Loader />
            ) : errorSimilar ? (
              <Message variant="danger">{errorSimilar}</Message>
            ) : (
              <Swiper
                spaceBetween={10}
                slidesPerView={4}
                pagination={{ clickable: true }}
             
              >
                {similarProducts.map((product) => (
                 <SwiperSlide key={product._id}>
                 <Link to={`/product/${product._id}`}>
                   <Image src={product.image} alt={product.name} fluid />
                   <div className="swiper-product-name">
                     {language === 'he' ? product.nameHe : product.name}
                   </div>
                   <div className="swiper-product-price">
                   {currencySymbol}{displayPrice(product.price)}
                   </div>
                   <Rating
                     value={product.rating}
                     text={`${product.numReviews} ${t('reviews-text')}`}
                   />
                 </Link>
               </SwiperSlide>
               
                ))}
             
              </Swiper>
            )}
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <h2>{t('reviews-text2')}</h2>
            {product.reviews.length === 0 && <Message>{t('no-reviews')}</Message>}
            <ListGroup variant="flush">
              {product.reviews.map((review) => (
                <ListGroup.Item key={review._id}>
                  <strong>{review.name}</strong>
                  <Rating value={review.rating} />
                  <p>{review.createdAt.substring(0, 10)}</p>
                  <p>{review.comment}</p>
                </ListGroup.Item>
              ))}
              <ListGroup.Item>
                <h2>{t('write-customer-review-text')}</h2>
                {successProductReview && (
                  <Message variant="success">
                    {t('review-success-text')}
                  </Message>
                )}
                {loadingProductReview && <Loader />}
                {errorProductReview && (
                  <Message variant="danger">{errorProductReview}</Message>
                )}
                {userInfo ? (
                  <Form onSubmit={submitHandler}>
                    <Form.Group controlId="rating">
                      <Form.Label>{t('rating-text')}</Form.Label>
                      <Form.Control
                        as="select"
                        value={rating}
                        onChange={(e) => setRating(e.target.value)}
                      >
                        <option value="">{t('select-text')}</option>
                        <option value="1">1 - {t('poor')}</option>
                        <option value="2">2 - {t('fair')}</option>
                        <option value="3">3 - {t('good')}</option>
                        <option value="4">4 - {t('very-good')}</option>
                        <option value="5">5 - {t('excellent')}</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="comment">
                      <Form.Label>{t('comment')}</Form.Label>
                      <Form.Control
                        as="textarea"
                        row="3"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                    <Button
                      disabled={loadingProductReview}
                      type="submit"
                      variant="primary"
                      className="mt-3 round"
                    >
                     {t('submit-button-text')}
                    </Button>
                  </Form>
                ) : (
                  <Message>
                    {t('please')} <Link to="/login">{t('sign-in-small')}</Link> {t('to-write-a-review-text')}{" "}
                  </Message>
                )}
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      </>
    )}
  </>
  );
};

export default ProductsScreen;

/*
In the code you provided, {match} is an object that is being passed to the ProductsScreen component as a prop. This object contains information about the current route that the component is being rendered on. Specifically, it contains information about the URL path parameters, query strings, and other details of the current route.

The match object is a property of the routeProps object, which is passed to the component by the React Router library. React Router uses this information to match the current URL to the appropriate component, and it passes the match object as a prop to the component so that the component can access the routing information.

The component is using the match object to extract the id of the product from the URL. The match.params object contains the dynamic segments of the URL, and in this case, the component is using match.params.id to access the id of the product from the URL.
Then the component uses the find() method to search for a product that has the same id as the one in the URL, and then it can use that product to display more information on the screen.

In short, the match prop allows the component to access the information about the current route and use that information to render the appropriate content.
*/
