import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Carousel, Image } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from './Loader'
import Message from './Message'
import { listTopProducts } from '../actions/productActions'
import { getRate } from '../currencyExchange'; // Assuming you have this function in this file

const ProductCarousel = () => {
  const dispatch = useDispatch()

  const productTopRated = useSelector((state) => state.productTopRated)
  const { loading, error, products } = productTopRated

  const language = useSelector((state) => state.language.language);
  
  const [exchangeRate, setExchangeRate] = useState(null);
  const [currencySymbol, setCurrencySymbol] = useState("$");

  useEffect(() => {
    const fetchExchangeRate = async () => {
      let rate = await getRate();
      setExchangeRate(rate);
    };
    setCurrencySymbol(language === "he" ? "₪" : "$");
    fetchExchangeRate();
  }, [language]);

  const displayPrice = (price) => {
    return (language === 'he' ? price : (price * exchangeRate)).toFixed(2);
  }

  useEffect(() => {
    dispatch(listTopProducts())
  }, [dispatch])

  useEffect(() => {
  }, [products, language]);

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant='danger'>{error}</Message>
  ) : (
    <Carousel pause='hover' className=''>
      {products.map((product) => (
        <Carousel.Item key={product._id}>
          <Link to={`/product/${product._id}`}>
            <Image src={product.image} alt={product.name} fluid />
            <Carousel.Caption className='carousel-caption'>
              <h2>
                {language === 'he' ? product.nameHe : product.name} ({currencySymbol}{displayPrice(product.price)})
              </h2>
            </Carousel.Caption>
          </Link>
        </Carousel.Item>
      ))}
    </Carousel>
  )
}

export default ProductCarousel
