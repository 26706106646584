import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { switchLanguage } from '../actions/languageActions';

const LanguageSwitcher = () => {
  const dispatch = useDispatch();
  const language = useSelector(state => state.language.language);

  const changeLanguage = (lang) => {
    dispatch(switchLanguage(lang));
  };

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    changeLanguage(selectedLanguage);
  };

  return (
    <div>
      <select className='round' value={language} onChange={handleLanguageChange}>
        <option value="en" disabled={language === 'en'}>
          English
        </option>
        <option value="he" disabled={language === 'he'}>
          Hebrew
        </option>
      </select>
    </div>
  );
};

export default LanguageSwitcher;

