import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  ListGroup,
  Button,
  Card,
  Image,
  Form,
} from "react-bootstrap";
import Message from "../components/Message";
import { useSelector, useDispatch } from "react-redux";
import { addToCart, removeFromCart } from "../actions/cartActions";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getRate } from "../currencyExchange";
import { login } from "../actions/userActions";

const CartScreen = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const productId = params.id;
  const qty = location.search ? Number(location.search.split("=")[1]) : 1;
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;
  const { t } = useTranslation();
  const language = useSelector((state) => state.language.language);
  const [exchangeRate, setExchangeRate] = useState(null);
  const [currencySymbol, setCurrencySymbol] = useState("₪");
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (productId) {
      dispatch(addToCart(productId, qty));
      // Clear the productId from the URL after adding the item to the cart
      navigate("/cart", { replace: true });
    }
  }, [dispatch, productId, cartItems, language, qty, navigate]);

  useEffect(() => {
    const fetchExchangeRate = async () => {
      let rate = await getRate();
      setExchangeRate(rate);
    };
    setCurrencySymbol(language === "he" ? "₪" : "$");
    fetchExchangeRate();
  }, [language]);

  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id));
  };

  const checkoutHandler = () => {
    navigate("/login?redirect=shipping");
  };
  const continueShopping = () => {
    navigate(-2);
  };

  return (
    <Row>
      <Col md={8}>
        <h1>{t("shopping-cart-title")}</h1>
        {cartItems.length === 0 || !userInfo ? (
          <Message>
            {userInfo ? (
              <>
                {t("cart-empty-text")} <Link to="/">{t("go-back-button")}</Link>
              </>
            ) : (
              <>
                {t("cart-empty-text")}{" "}
                <Link to="/sign-in">{t("sign-in-text")}</Link>
              </>
            )}
          </Message>
        ) : (
          <ListGroup variant="flush">
            {cartItems.map((item) => (
              <ListGroup.Item key={item.product}>
                <Row>
                  <Col md={2}>
                    <Image
                      src={item.image}
                      alt={language === "he" ? item.nameHe : item.name}
                      fluid
                      rounded
                    />
                  </Col>
                  <Col md={3}>
                    <Link to={`/product/${item.product}`}>
                      {language === "he" ? item.nameHe : item.name}
                    </Link>
                  </Col>
                  <Col md={2}>
                    {currencySymbol}
                    {(
                      item.price * (language === "he" ? 1 : exchangeRate)
                    ).toFixed(2)}
                  </Col>
                  <Col md={2}>
                    <Form.Control
                      as="select"
                      value={item.qty}
                      onChange={(e) =>
                        dispatch(
                          addToCart(item.product, Number(e.target.value))
                        )
                      }
                    >
                      {Number.isInteger(item.countInStock) &&
                        item.countInStock > 0 &&
                        [...Array(item.countInStock).keys()].map((x) => (
                          <option key={x + 1} value={x + 1}>
                            {x + 1}
                          </option>
                        ))}
                    </Form.Control>
                  </Col>
                  <Col md={2}>
                    <Button
                      type="button "
                      variant="light"
                      className="round"
                      onClick={() => removeFromCartHandler(item.product)}
                    >
                      <i className="fas fa-trash"></i>
                    </Button>
                  </Col>
                </Row>
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </Col>
      <Col md={4}>
        <Card>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <h2>
                {t("subtotal-text")} (
                {userInfo
                  ? cartItems.reduce((acc, item) => acc + item.qty, 0)
                  : 0}
                ){t("items-text")}
              </h2>

              {userInfo && (
                <>
                  {currencySymbol}
                  {cartItems
                    .reduce(
                      (acc, item) =>
                        item.price
                          ? acc +
                            item.qty *
                              item.price *
                              (language === "he" ? 1 : exchangeRate)
                          : acc,
                      0
                    )
                    .toFixed(2)}
                </>
              )}
            </ListGroup.Item>

            <ListGroup.Item style={{ display: "flex" }}>
              <Button
                type="button "
                className="btn-block round"
                disabled={cartItems.length === 0 || !userInfo}
                onClick={checkoutHandler}
                style={{ margin: "3px" }}
              >
                {t("proceed-to-checkout-button")}
              </Button>
              <Button
                type="button"
                className="btn-block round"
                onClick={continueShopping}
                style={{ margin: "3px" }}
              >
                {t("continue-shopping-button")}
              </Button>
            </ListGroup.Item>
          </ListGroup>
        </Card>
      </Col>
    </Row>
  );
};

export default CartScreen;
