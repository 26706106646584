import { SWITCH_LANGUAGE } from "../constants/languageConstans";

const initialState = {
    language: 'he',
  };
  
  export const languageReducer = (state = initialState, action) => {
    switch (action.type) {
      case SWITCH_LANGUAGE:
        return {
          ...state,
          language: action.payload,
        };
      default:
        return state;
    }
  };
  
 