import React, { useEffect, useState } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { useNavigate } from 'react-router';
import { Table, Button, Row, Col, Form, Pagination } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import Loader from '../components/Loader';
import { listOrders } from '../actions/orderActions';

const OrderListScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const orderList = useSelector((state) => state.orderList);
  const { loading, error, orders, totalPages } = orderList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  
  const [pageNumber, setPageNumber] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');

  const searchSubmitHandler = (e) => {
    e.preventDefault();
    setSearchQuery(e.target.search.value);
    setPageNumber(1);
  };

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listOrders(pageNumber, searchQuery));
      
    } else {
      navigate('/login');
    }
  }, [dispatch,navigate, userInfo, pageNumber, searchQuery]);

  const pageChangeHandler = (eventKey) => {
    setPageNumber(eventKey);
  };
 
  return (
    <>
      <h1>Orders</h1>
      <Row>
        <Col>
          <Form onSubmit={searchSubmitHandler}>
            <Form.Group controlId='search' className='d-flex'>
              <Form.Control
                type='text'
                placeholder='Search by user ID...'
                className='mr-2'
              />
              <Button type='submit' className='btn btn-primary round'>
                Search
              </Button>
            </Form.Group>
          </Form>
        </Col>
      </Row>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <Table striped bordered hover responsive className='table-sm'>
            <thead>
              <tr>
                <th>ORDER ID</th>
                <th>USER ID</th>
                <th>USER NAME</th>
                <th>DATE</th>
                <th>TOTAL</th>
                <th>PAID</th>
                <th>DELIVERED</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
               
                <tr key={order._id}>
                  <td>{order._id}</td>
                  <td>{order.user && order.user._id}</td>
                  <td>{order.user && order.user.name}</td>
                  <td>{order.createdAt.substring(0, 10)}</td>
                  <td>${order.totalPrice}</td>
                  <td>
                    {order.isPaid ? (
                      order.paidAt.substring(0, 10)
                    ) : (
                      <i className='fas fa-times' style={{ color: 'red' }}></i>
                    )}
                  </td>
                  <td>
                    {order.isDelivered ? (
                      order.deliveredAt.substring(0, 10)
                    ) : (
                      <i className='fas fa-times' style={{ color: 'red' }}></i>
                    )}
                  </td>
                  <td>
                    <LinkContainer to={`/orders/${order._id}`}>
                      <Button variant='light' className='btn-sm round'>
                        Details
                      </Button>
                      </LinkContainer>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination>
            {[...Array(totalPages).keys()].map((x) => (
              <Pagination.Item
                key={x + 1}
                active={x + 1 === pageNumber}
                onClick={() => pageChangeHandler(x + 1)}
              >
                {x + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </>
      )}
    </>
  );
};
export default OrderListScreen;
